import React, { useState } from "react";
import { Box, Dialog, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import TermsPP from "./TermsPP";
import TermsCompany from "./TermsCompany";
import TermsLocation from "./TermsLocation";
import TermsServiceIndividuals from "./TermsServiceIndividuals";
import TermsServiceBusiness from "./TermsServiceBusiness";
import { analyticsEvent, searchEncoding } from "../../lib/common";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TermsVersionType from "./TermsVersionType";

const footerStyles = makeStyles({
  background: {
    backgroundColor: "#fff",
    width: "64.5833vw",
    position: "static",
    margin: "4.7656vh auto 5.9375vh",
  },
  //상단 버튼셋트
  topbutton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  worksbe: {
    width: "6.4063vw",
  },
  buttonfacebook: {
    width: "1.3542vw",
  },
  buttoninstagram: {
    width: "1.3542vw",
    marginLeft: "1.25vw",
  },
  bottombar: {
    display: "flex",
    justifyContent: "space-between",
  },
  //텍스트 리시트
  noto16bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 700,
    lineHeight: "2.5vh",
    letterSpacing: "-1px",
    whiteSpace: "nowrap",
    color: "#333375",
  },
  text_line: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 300,
    lineHeight: "2.5vh",
    letterSpacing: "0px",
    whiteSpace: "nowrap",
  },
  noto11light: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 300,
    lineHeight: "2.6563vh",
    letterSpacing: "-1px",
    whiteSpace: "nowrap",
    color: "#333375",
  },
  mon11light: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 300,
    lineHeight: "2.6563vh",
    letterSpacing: "0px",
    color: "#333375",
  },
  mon16regular: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 400,
    lineHeight: "2.5vh",
    letterSpacing: "0px",
  },
  noto16regular: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    fontWeight: 400,
    lineHeight: "2.5vh",
    letterSpacing: "-1px",
    whiteSpace: "nowrap",
    color: "#333375",
  },
  mon10medium: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.6771vw",
    fontWeight: 500,
    lineHeight: "3.9063vh",
    marginTop: "20px",
    color: "#333375",
  },
  nanum10bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.6771vw",
    fontWeight: 700,
    lineHeight: "3.9063vh",
    color: "#333375",
  },
  dialogFull: {
    "&.MuiDialog-root .MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperScrollPaper": {
      width: "100%",
      height: "100%",
      padding: "0 32px",
      margin: 0,
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
  dialogFullWeb: {
    "&.MuiDialog-root .MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperScrollPaper": {
      width: "50%",
      height: "98%",
      padding: "0 32px",
      borderRadius: "10px",
      margin: 0,
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
});
const Footer = (props) => {
  const isss = useMediaQuery("(min-width:1281px)");
  const classes = footerStyles();
  const [termsPPOpen, setTermsPPOpen] = useState(false);
  const handleTermsPPClose = () => setTermsPPOpen(false);
  const [termsCompanyOpen, setTermsCompanyOpen] = useState(false);
  const handleTermsCompanyClose = () => setTermsCompanyOpen(false);
  const [termsLocationOpen, setTermsLocationOpen] = useState(false);
  const handleTermsLocationClose = () => setTermsLocationOpen(false);
  const [termsServiceOpen, setTermsServiceOpen] = useState(false);
  const handleTermsServiceClose = () => setTermsServiceOpen(false);
  const [termsServiceBusinessOpen, setTermsServiceBusinessOpen] = useState(false);
  const handleTermsServiceBusinessClose = () => setTermsServiceBusinessOpen(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [termsDialog, setTermsDialog] = useState(false);
  const handleDialogClose = () => setTermsDialog(false);
  const [dialogNaviTitle, setDialogNaviTitle] = useState("");
  const [termsName, setTermsName] = useState("");
  const [htmlName, setHtmlName] = useState("");

  const clickFacebook = () => {
    try {
      analyticsEvent(
        "HOMEPAGE",
        "GO_FACEBOOK",
        String.format("FROM:{0}|{1}", searchEncoding(location.pathname), searchEncoding(location.search))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const clickInsta = () => {
    try {
      analyticsEvent(
        "HOMEPAGE",
        "GO_INSTAGRAM",
        String.format("FROM:{0}|{1}", searchEncoding(location.pathname), searchEncoding(location.search))
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      {isss ? (
        <Grid container direction="column" className={classes.background}>
          <Grid item className={classes.topbutton}>
            <Typography className={classes.noto16bold} style={{ wordBreak: "keep-all", display: "flex" }}>
              <span
                onClick={() => {
                  setDialogNaviTitle("회사소개");
                  setTermsName(null);
                  setHtmlName("/txt/TermsCompany.html");
                  setTermsDialog(true);
                }}
              >
                회사소개
              </span>
              <Box style={{ minWidth: "1.25vw" }} />

              <span
                onClick={() => {
                  setDialogNaviTitle("개인회원 이용약관");
                  setTermsName("/txt/terms_service_individuals");
                  setHtmlName("/txt/terms_service_individuals.html");
                  setTermsDialog(true);
                }}
              >
                이용약관 (개인)
              </span>

              <Box style={{ minWidth: "1.25vw" }} />
              <span
                onClick={() => {
                  setDialogNaviTitle("기업회원 이용약관");
                  setTermsName("/txt/terms_service_business");
                  setHtmlName("/txt/terms_service_business.html");
                  setTermsDialog(true);
                }}
              >
                이용약관 (기업)
              </span>
              <Box style={{ minWidth: "1.25vw" }} />
              <span
                onClick={() => {
                  setDialogNaviTitle("위치 서비스 이용약관");
                  setTermsName("/txt/terms_location");
                  setHtmlName("/txt/terms_location.html");
                  setTermsDialog(true);
                }}
              >
                위치서비스 이용약관
              </span>
              <Box style={{ minWidth: "1.25vw" }} />
              <span
                onClick={() => {
                  setDialogNaviTitle("개인정보 처리방침");
                  setTermsName("/txt/terms_PP");
                  setHtmlName("/txt/terms_PP.html");
                  setTermsDialog(true);
                }}
              >
                개인정보처리방침
              </span>
            </Typography>
            <Grid>
              <Box display="flex">
                <a href="https://www.facebook.com/thebulja" target="_blank" rel="noreferrer">
                  <img
                    src={require("../../images/thebulja/buttonfb.svg").default}
                    className={classes.buttonfacebook}
                    onClick={clickFacebook}
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/thebulja/" target="_blank" rel="noreferrer">
                  <img
                    src={require("../../images/thebulja/buttonis.svg").default}
                    className={classes.buttoninstagram}
                    onClick={clickInsta}
                    alt=""
                  />
                </a>
              </Box>
            </Grid>
          </Grid>

          <Divider style={{ backgroundColor: "#333375", margin: "2.3438vh 0 2.1094vh" }} />
          <Grid item className={classes.bottombar}>
            <Grid className={classes.bottombar} style={{ alignItems: "flex-start" }}>
              <img
                src={require("../../images/thebulja/worksbeci.svg").default}
                className={classes.worksbe}
                style={{ margin: "0.9375vh 1.9271vw 0 0" }}
                alt=""
              />
              <Grid>
                <Typography className={classes.noto16bold}>(주) 웍스비</Typography>
                <Typography className={classes.noto11light}>
                  사업자등록번호 :{" "}
                  <Typography component="span" className={classes.mon11light}>
                    122-86-47366
                  </Typography>
                  &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;대표 : 김현호&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;대표번호 :{" "}
                  <Typography component="span" className={classes.mon11light}>
                    <a href="tel:1644-2163" style={{ textDecoration: "unset", color: "#333375" }}>
                      1644-2163
                    </a>
                    <br />
                  </Typography>
                  서울시 서초구 매헌로16길 32 (AI양재허브), 301호
                  <br />
                  직업정보제공사업신고번호 : 서울청 제2023-9호
                  <br />
                  통신판매업신고번호 : 제 2023-서울서초-1611호
                </Typography>
                <Typography className={classes.mon10medium}>
                  <Typography component="span" className={classes.nanum10bold}>
                    ⓒ
                  </Typography>{" "}
                  WorksBe Inc. All right reserved
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.noto16regular}>
              고객문의 :{" "}
              <Typography component="span" className={classes.mon16regular}>
                <a href="mailto:help@worksbe.com" style={{ color: "#333375", textDecoration: "unset" }}>
                  help@worksbe.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </Typography>
              제휴문의 :{" "}
              <Typography component="span" className={classes.mon16regular}>
                <a href="mailto:partnership@worksbe.com" style={{ color: "#333375", textDecoration: "unset" }}>
                  partnership@worksbe.com
                </a>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          className={classes.background}
          style={{ width: "620px", margin: "54px auto 114px" }}
        >
          <Grid item>
            <Grid container className={classes.topbutton}>
              <Grid item>
                <img
                  src={require("../../images/thebulja/worksbeci.svg").default}
                  className={classes.worksbe}
                  style={{ width: "189px" }}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Box display="flex">
                  <a href="https://www.facebook.com/thebulja" target="_blank" rel="noreferrer">
                    <img
                      src={require("../../images/thebulja/buttonfb.svg").default}
                      className={classes.buttonfacebook}
                      onClick={clickFacebook}
                      style={{ width: "40px" }}
                      alt=""
                    />
                  </a>
                  <a href="https://www.instagram.com/thebulja/" target="_blank" rel="noreferrer">
                    <img
                      src={require("../../images/thebulja/buttonis.svg").default}
                      className={classes.buttoninstagram}
                      onClick={clickInsta}
                      style={{ width: "40px" }}
                      alt=""
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              className={classes.noto16bold}
              style={{ fontSize: "26px", lineHeight: "40px", marginTop: "35px" }}
            >
              (주) 웍스비
            </Typography>
            <Typography
              className={classes.noto11light}
              style={{ fontSize: "22px", lineHeight: "38px", marginTop: "8px" }}
            >
              사업자등록번호 :{" "}
              <Typography
                component="span"
                className={classes.mon11light}
                style={{ fontSize: "22px", lineHeight: "38px" }}
              >
                122-86-47366
              </Typography>{" "}
              | 대표 : 김현호
              <br />
              대표번호 :{" "}
              <Typography
                component="span"
                className={classes.mon11light}
                style={{ fontSize: "22px", lineHeight: "38px" }}
              >
                <a href="tel:1644-2163" style={{ textDecoration: "unset", color: "#202020" }}>
                  1644-2163
                </a>
                <br />
              </Typography>
              서울시 서초구 매헌로16길 32 (AI양재허브), 301호
              <br />
              직업정보제공사업신고번호 : 서울청 제2023-9호
              <br />
              통신판매업신고번호 : 제 2023-서울서초-1611호
            </Typography>
            <Typography
              className={classes.noto16regular}
              style={{ fontSize: "22px", lineHeight: "38px", marginTop: "8px" }}
            >
              고객문의 :{" "}
              <Typography component="span" className={classes.mon16regular}>
                <a
                  href="mailto:help@worksbe.com"
                  style={{ fontSize: "22px", lineHeight: "38px", color: "#333375", textDecoration: "unset" }}
                >
                  help@worksbe.com
                </a>
                <br />
              </Typography>
              제휴문의 :{" "}
              <Typography component="span" className={classes.mon16regular}>
                <a
                  href="mailto:partnership@worksbe.com"
                  style={{ fontSize: "22px", lineHeight: "38px", color: "#333375", textDecoration: "unset" }}
                >
                  partnership@worksbe.com
                </a>
              </Typography>
            </Typography>

            <Divider style={{ backgroundColor: "#333375", margin: "24px 0 26px" }} />

            <Typography
              className={classes.noto16bold}
              style={{ wordBreak: "keep-all", fontSize: "24px", lineHeight: "48px" }}
            >
              <span
                onClick={() => {
                  setDialogNaviTitle("개인회원 이용약관");
                  setTermsName("/txt/terms_service_individuals");
                  setHtmlName("/txt/terms_service_individuals.html");
                  setTermsDialog(true);
                }}
              >
                이용약관 (개인)
              </span>
              <Typography
                component="span"
                className={classes.text_line}
                style={{ fontSize: "24px", lineHeight: "48px" }}
              >
                &nbsp;|&nbsp;
              </Typography>
              <span
                onClick={() => {
                  setDialogNaviTitle("기업회원 이용약관");
                  setTermsName("/txt/terms_service_business");
                  setHtmlName("/txt/terms_service_business.html");
                  setTermsDialog(true);
                }}
              >
                이용약관 (기업)
              </span>
              <br />
              <span
                onClick={() => {
                  setDialogNaviTitle("위치 서비스 이용약관");
                  setTermsName("/txt/terms_location");
                  setHtmlName("/txt/terms_location.html");
                  setTermsDialog(true);
                }}
              >
                위치 서비스 이용약관
              </span>
              <Typography
                component="span"
                className={classes.text_line}
                style={{ fontSize: "24px", lineHeight: "48px" }}
              >
                &nbsp;|&nbsp;
              </Typography>
              <span
                onClick={() => {
                  setDialogNaviTitle("개인정보 처리방침");
                  setTermsName("/txt/terms_PP");
                  setHtmlName("/txt/terms_PP.html");
                  setTermsDialog(true);
                }}
              >
                개인정보 처리방침
              </span>
              <Typography
                component="span"
                className={classes.text_line}
                style={{ fontSize: "24px", lineHeight: "48px" }}
              >
                &nbsp;|&nbsp;
              </Typography>
              <span
                onClick={() => {
                  setDialogNaviTitle("회사소개");
                  setTermsName(null);
                  setHtmlName("/txt/TermsCompany.html");
                  setTermsDialog(true);
                }}
              >
                회사소개
              </span>
            </Typography>
            <Typography className={classes.mon10medium} style={{ fontSize: "20px", lineHeight: "50px" }}>
              <Typography
                component="span"
                className={classes.nanum10bold}
                style={{ fontSize: "20px", lineHeight: "50px" }}
              >
                ⓒ
              </Typography>{" "}
              WorksBe Inc. All right reserved
            </Typography>
          </Grid>
        </Grid>
      )}
      <Dialog
        className={isss ? classes.dialogFullWeb : classes.dialogFull}
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        open={termsDialog}
        hideBackdrop={true}
      >
        <TermsVersionType
          handleClose={handleDialogClose}
          termsName={termsName}
          htmlName={htmlName}
          setHtmlName={setHtmlName}
          naviTitle={dialogNaviTitle}
        />
      </Dialog>
    </Box>
  );
};

export default Footer;
