import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "../icons/CloseIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { analyticsEvent, searchEncoding } from "../../lib/common";

const termsCompanyStyles = () => ({
  body: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1110,
    backgroundColor: "#fff",
    margin: 0,
  },
  closeButton: {
    margin: "-3px 20px 20px",
    color: "#202020",
  },
  title: {
    fontSize: 18,
    lineHeight: "24px",
    color: "#202020",
    fontWeight: 500,
    position: "absolute",
    marginTop: -6,
    paddingLeft: 17,
  },
  centertext: {
    textAlign: "center",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 500,
    color: "#747474",
    position: "absolute",
    top: "40%",
  },
  grayText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#747474",
  },
});

const TermsCompany = (props) => {
  const { classes } = props;
  const topHeight = 39 - 16;
  const navigate = useNavigate();
  const handleCloseDefault = () => navigate(-1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    try {
      analyticsEvent("HOMEPAGE", "VIEW", String.format("TERMS_COMPANY_{0}", searchEncoding(location.search)));
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <Grid container direction="column" className={classes.body}>
      <Grid item style={{ marginTop: topHeight }}>
        <CloseIcon className={classes.closeButton} onClick={handleCloseDefault}></CloseIcon>
        <Typography component="span" className={classes.title}>
          회사정보
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item className={classes.centertext}>
            <Typography className={classes.grayText}>㈜웍스비</Typography>
            <Typography className={classes.grayText}>서울시 서초구 매헌로16길 32 (AI양재허브), 301호</Typography>
            <Typography className={classes.grayText}>대표: 김현호 / 대표번호: 1644-2163</Typography>
            <Typography className={classes.grayText}>사업자등록번호: 122-86-47366</Typography>
            <Typography className={classes.grayText}>직업정보제공사업신고번호: 서울청 제2023-9호</Typography>
            <Typography className={classes.grayText}>통신판매업신고번호: 제 2023-서울서초-1611호</Typography>
            <Typography className={classes.grayText}>홈페이지: www.worksbe.com</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(termsCompanyStyles)(TermsCompany);
