import React from 'react'
import Animation1x from './workbe_275x240.gif'
import Animation2x from './workbe_275x240.gif'
import {withStyles} from "@material-ui/styles";

const loadingCircleStyles = () => ({
  loading: {
    position: 'fixed',
    display: 'block',
    top: '55%',
    left: '50%',
    width: 125 / 2,
    height: 80 / 2,
    marginLeft: -31,
    marginTop: -30,
    zIndex: 2010,
  },
})

const LoadingCircle = (props) => (
  <img srcSet={Animation1x + ' 1x,' + Animation2x + ' 2x'} className={props.classes.loading} alt="loading..." />
)

export default withStyles(loadingCircleStyles)(LoadingCircle)
