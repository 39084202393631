import React, {useEffect} from 'react'

const KakaoCustomerCenter = () => {
  useEffect(() => {
    window.location.href = 'https://pf.kakao.com/_ghCxoK/chat'
  }, [])

  return null
}

KakaoCustomerCenter.propTypes = {}
export default KakaoCustomerCenter
