import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const TheBulja63Styles = makeStyles({
  background: {
    backgroundColor: "#FBFBFB",
  },
  center_box: {
    width: "100vw",
    // minHeight: '1280px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "row",
    marginTop: "20.9375vh",
    marginBottom: "14.5313vh",
  },
  center_box_m: {
    minHeight: "1280px",
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "column",
    // marginBottom: '50px',
    justifyContent: "center",
  },
  text_box: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  text_box_m: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    whiteSpace: "nowrap",
  },
  text_center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    whiteSpace: "nowrap",
  },
  text_center_m: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    whiteSpace: "nowrap",
  },
  whiteBox: {
    width: "52.0833vw",
    minWidth: "1000px",
    height: "38.4375vh",
    minHeight: "500px",
    borderRadius: "2vw",
    boxShadow: "0.2vw 0.3vh 60px #20202022",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  whiteBox_m: {
    width: "620px",
    height: "600px",
    borderRadius: "20px",
    boxShadow: "0.2vw 0.3vh 60px #20202022",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  text_svg: {
    width: "7.1354vw",
    margin: "-6.25vh 0 5px 39.8438vw",
  },
  text_svg_m: {
    width: "210px",
    margin: "47px 0 6px",
  },
  circle_box: {
    width: "160px",
    // width: '6.77vw',
    border: "1px solid #202020",
    position: "relative",
    paddingBottom: "100%",
  },
  text_light: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 300,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_30_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "1.4583vw",
    lineHeight: "3vh",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-1px",
  },
  text_16_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    lineHeight: "2vh",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-1px",
  },
  text_16_regular: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.7813vw",
    lineHeight: "2vh",
    fontWeight: 400,
    color: "#333375",
    letterSpacing: "-1px",
  },
});

const TheBulja63 = (props) => {
  const classes = TheBulja63Styles();
  const isss = useMediaQuery("(min-width:1281px)");

  return (
    <Box style={{ width: "100vw", backgroundColor: "#FFF", position: "relative" }}>
      <Grid container direction="column" className={classes.background}>
        <Grid container className={isss ? classes.center_box : classes.center_box_m}>
          <Grid item className={isss ? classes.text_box : classes.text_box_m}>
            {/* <img src={require('../../images/thebulja/page6_1_red_box.svg').default} style={isss ? {margin: '14.375vh 0 2.8125vh', width: '6.77vw'} 
                                                                                                            : {margin: '160px 0 37px', width: '156px'}} alt=''/> */}
            <Typography className={classes.text_light} style={isss ? {} : { display: "none" }}>
              맞춤형 조합을 통해 지금보다
            </Typography>
            <Grid style={isss ? { display: "flex", marginBottom: "5.3125vh" } : { display: "none" }}>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px" }}>
                더 많은 일자리 기회를
              </Typography>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px", textAlign: "center" }}>
                &nbsp;얻을 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={isss ? classes.whiteBox : classes.whiteBox_m}>
            <img
              src={require("../../images/thebulja/page6_icon.svg").default}
              style={isss ? { height: "5.625vh", margin: "3.125vh 0 6.1719vh" } : { height: "56px" }}
            />
            <Grid className={isss ? classes.text_center : classes.text_center_m}>
              <Grid style={{ display: "flex" }}>
                <img
                  src={require("../../images/thebulja/page6_2_Ac_bold.svg").default}
                  style={
                    isss ? { width: "6.7708vw", margin: " 0.5208vw 0 0" } : { width: "160px", margin: "12px 15px 20px" }
                  }
                />
                <img
                  src={require("../../images/thebulja/page6_2_Bc_bold.svg").default}
                  style={
                    isss
                      ? { width: "6.7708vw", margin: "0 0 0 0.5208vw" }
                      : { width: "160px", margin: "12px 15px 20px" }
                  }
                />
              </Grid>
              <img
                src={require("../../images/thebulja/page6_2_plus.svg").default}
                style={isss ? { width: "1.25vw", margin: "0 1.5625vw 0 1.0417vw" } : { width: "24px" }}
              />
              <Grid style={{ display: "flex" }}>
                <img
                  src={require("../../images/thebulja/page6_2_Ac_red1.svg").default}
                  style={
                    isss ? { width: "10.8333vw", margin: "0 0.5208vw 0 0" } : { width: "240px", margin: "20px 23px 0" }
                  }
                />
                <img
                  src={require("../../images/thebulja/page6_2_Bc_red2.svg").default}
                  style={
                    isss ? { width: "10.8333vw", margin: "0 0 0 0.5208vw" } : { width: "240px", margin: "20px 23px 0" }
                  }
                />
              </Grid>
            </Grid>
            <Typography
              className={classes.text_16_regular}
              style={isss ? { margin: "2.6563vh 0 2.5781vh" } : { display: "none" }}
            >
              더벌자만의 맞춤형 조합으로&nbsp;<span className={classes.text_16_bold}>구직기회도 UP! 채용률도 UP!</span>
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={require("../../images/thebulja/page6_2_text_red.svg").default}
              className={isss ? classes.text_svg : classes.text_svg_m}
            />
          </Grid>
          <Grid item>
            <img
              src={require("../../images/thebulja/page6_2_icon.svg").default}
              style={isss ? { width: "4.6875vw", marginLeft: "39.7917vw" } : { width: "90px" }}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            position: "absolute",
            bottom: isss ? "14.4531vh" : "50px",
          }}
        >
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_red.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TheBulja63;
