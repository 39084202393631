import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const TheBulja62Styles = makeStyles({
  background: {
    backgroundColor: "#FBFBFB",
  },
  center_box: {
    width: "100vw",
    // minHeight: '1280px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "row",
    marginTop: "20.9375vh",
    marginBottom: "14.5313vh",
  },
  center_box_m: {
    minHeight: "1280px",
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "column",
    // marginBottom: '50px',
    justifyContent: "center",
  },
  text_box: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  text_box_m: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    whiteSpace: "nowrap",
  },
  whiteBox: {
    width: "39.5833vw",
    minWidth: "760px",
    height: "33.2031vh",
    minHeight: "425px",
    borderRadius: "2vw",
    boxShadow: "0.2vw 0.3vh 60px #20202022",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  whiteBox_m: {
    width: "620px",
    height: "600px",
    borderRadius: "20px",
    boxShadow: "0.2vw 0.3vh 60px #20202022",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  text_svg: {
    width: "7.1354vw",
    margin: "-6.25vh 0 5px 39.8438vw",
  },
  text_svg_m: {
    width: "210px",
    margin: "47px 0 6px",
  },
  circle_box: {
    width: "160px",
    // width: '6.77vw',
    border: "1px solid #202020",
    position: "relative",
    paddingBottom: "100%",
  },
  text_light: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 300,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_30_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "1.4583vw",
    lineHeight: "3vh",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-1px",
  },
  text_20_bold_mt: {
    fontFamily: "Noto Sans Korean",
    fontSize: "1.25vw",
    lineHeight: "3vh",
    fontWeight: 700,
    color: "#5F5F69",
    letterSpacing: "-1px",
  },
  text_18_regular: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.9375vw",
    lineHeight: "3vh",
    fontWeight: 700,
    color: "#5F5F69",
    letterSpacing: "-1px",
  },
  text_18_regular_mt: {
    fontFamily: "Noto Sans Korean",
    fontSize: "0.9375vw",
    lineHeight: "3vh",
    fontWeight: 700,
    color: "#5F5F69",
    letterSpacing: "-1px",
  },
});

const TheBulja62 = (props) => {
  const classes = TheBulja62Styles();
  const isss = useMediaQuery("(min-width:1281px)");

  return (
    <Box style={{ width: "100vw", backgroundColor: "#FFF", position: "relative" }}>
      <Grid container direction="column" className={classes.background}>
        <Grid container className={isss ? classes.center_box : classes.center_box_m}>
          <Grid item className={isss ? classes.text_box : classes.text_box_m}>
            {/* <img src={require('../../images/thebulja/page6_1_red_box.svg').default} style={isss ? {margin: '14.375vh 0 2.8125vh', width: '6.77vw'} 
                                                                                                            : {margin: '160px 0 37px', width: '156px'}} alt=''/> */}
            <Typography className={classes.text_light} style={isss ? {} : { display: "none" }}>
              맞춤형 조합을 통해 지금보다
            </Typography>
            <Grid style={isss ? { display: "flex", marginBottom: "5.3125vh" } : { display: "none" }}>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px" }}>
                더 많은 일자리 기회를
              </Typography>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px", textAlign: "center" }}>
                &nbsp;얻을 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={isss ? classes.whiteBox : classes.whiteBox_m} style={{ position: "relative" }}>
            <Grid
              className={classes.text_30_bold}
              style={
                isss ? { position: "absolute", top: "3.75vh" } : { fontSize: "30px", position: "absolute", top: "30px" }
              }
            >
              기존 플랫폼
            </Grid>
            <Grid style={{ display: "flex" }}>
              <img
                src={require("../../images/thebulja/page6_2_Ac.svg").default}
                style={
                  isss
                    ? { width: "6.7708vw", margin: "56px 0.5208vw 31px" }
                    : { width: "160px", margin: "52px 15px 62px" }
                }
              />
              <img
                src={require("../../images/thebulja/page6_2_Bc.svg").default}
                style={
                  isss
                    ? { width: "6.7708vw", margin: "56px 0.5208vw 31px" }
                    : { width: "160px", margin: "52px 15px 62px" }
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <img
              src={require("../../images/thebulja/page6_2_text.svg").default}
              className={isss ? classes.text_svg : classes.text_svg_m}
            />
          </Grid>
          <Grid item>
            <img
              src={require("../../images/thebulja/page6_2_icon.svg").default}
              style={isss ? { width: "4.6875vw", marginLeft: "39.7917vw" } : { width: "90px" }}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            position: "absolute",
            bottom: isss ? "14.4531vh" : "50px",
          }}
        >
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_red.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TheBulja62;
