import axios from "axios"
import ReactGA from "react-ga4"

String.format = function() {
    let s = arguments[0]
    for (let i = 0, iMax=arguments.length - 1; i < iMax; ++i) {
        const reg = new RegExp("\\{" + i + "\\}", "gm")
        s = s.replace(reg, arguments[i + 1])
    }
    return s
}

export function analytics(category, action, label) {
    try {
        return axios.post(String.format('/api/analytics/{0}/{1}/{2}', category, action, label))
    } catch (e) {
        console.log(e)
    }
}

export const getServiceUrl = () =>onDev()?
    'http://127.0.0.1:5000': onLive() ?
        'https://server-chzoo4dyoq-du.a.run.app' :
        'https://server-yth6w665eq-du.a.run.app'


export const onDev = () =>
    window.location.href.startsWith('http://localhost:7000') ||
    window.location.href.startsWith('http://127.0.0.1:7000') ||
    window.location.href.startsWith('http://localhost:7001') ||
    window.location.href.startsWith('http://127.0.0.1:7001')


export const onLive = () =>
    window.location.href.startsWith('https://www.thebulja.com') ||
    window.location.href.startsWith('http://www.thebulja.com') ||
    window.location.href.startsWith('https://www.worksbe.com') ||
    window.location.href.startsWith('http://www.worksbe.com') ||
    window.location.href.startsWith('https://thebulja.com') ||
    window.location.href.startsWith('http://thebulja.com') ||
    window.location.href.startsWith('https://worksbe.com') ||
    window.location.href.startsWith('http://worksbe.com')


export const analyticsEvent = (category, action, label, value=1) => {
    if (onLive()) {
        ReactGA.event({
            category,
            action,
            value,
            label,
        })
        try {
            analytics(
                category,
                action,
                label.replace(/\//g, '_')).then(r => {/*console.log(r)*/}).catch(error=>{console.log(error)})
        } catch (e) {
            console.log(e)
        }
    }
}

export const replaceStr = (target, src, dst) => {
    if (!target) {
        return ''
    }
    try {
        return target.toString().replaceAll(src, dst)
    } catch (e) {
        console.log(e)
        try {
            return target.toString().split(src).join(dst)
        } catch (e2) {
            console.log(e2)
        }
    }
    return target
}


export const searchEncoding = (search) => {
    let tempStr = search
    if (!tempStr) {
        return ''
    }
    tempStr = replaceStr(tempStr, '/', '_')
    tempStr = replaceStr(tempStr, '?', '_')
    tempStr = replaceStr(tempStr, '&', '_')
    return encodeURIComponent(tempStr)
}
