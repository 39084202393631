import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja8Styles = makeStyles({
    background: {
        backgroundColor: '#FFF',
        justifyContent: 'center',
        minHeight: '1280px',
    },
    center_box: {
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        minWidth: '1240px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row',
        margin: '14.1406vh auto',
    },
    center_box_m: {
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        flexFlow: 'column',
    },
    page_phone: {
        width: '21.875vw',
        minWidth: '420px',
        borderRadius: '2vw',
        boxShadow: '0.2vw 0.3vh 60px #20202022',
        margin: '72px 0 -144px 0',
    },
    
    text_box: {
        //position: 'absolute',
        left: '0%', 
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',    
        height: '34.2188vh',
    },
    text_box_m: {
        //position: 'absolute', 
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        marginTop: '160px',
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',

    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',  
    },
})

const TheBulja8 = props => {
    const classes = TheBulja8Styles()
    const isss = useMediaQuery('(min-width:1281px)')

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                {isss ?
                <Grid container className={classes.center_box}>
                    <Grid item xs={6}>
                        <Grid className={classes.text_box}>
                            <img src={require('../../images/thebulja/page8_red_box.svg').default} style={{width: '6.77vw'}} alt='' />
                            <Box style={{minHeight: '2.89vh'}}/>
                            <Typography className={classes.text_light}>공고 내용 (필요한 근로시간,</Typography>
                            <Typography className={classes.text_light}>업무, 인건비 등)에 적합한</Typography>
                            <Typography className={classes.text_bold}>최적의 인재를 알아서</Typography>
                            <Typography className={classes.text_bold}>찾아 드립니다.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <img src={require('../../images/thebulja/page8_img.svg').default} className={classes.page_phone} alt=''/>
                    </Grid>
                </Grid>
                :
                <Grid container className={classes.center_box_m}>
                <Grid item>
                    <Grid className={classes.text_box_m}>
                        <img src={require('../../images/thebulja/page8_red_box.svg').default} style={{width: '156px'}} alt='' />
                        <Box style={{minHeight: '37px'}}/>
                        <Typography className={classes.text_light} style={{fontSize: '46px'}}>공고 내용 (필요한 근로시간,</Typography>
                        <Typography className={classes.text_light} style={{fontSize: '46px'}}>업무, 인건비 등)에 적합한</Typography>
                        <Typography className={classes.text_bold}  style={{fontSize: '46px'}}>최적의 인재를 알아서</Typography>
                        <Typography className={classes.text_bold}  style={{fontSize: '46px'}}>찾아 드립니다.</Typography>
                    </Grid>
                </Grid>
                    <Grid item style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <img src={require('../../images/thebulja/page8_img.svg').default} className={classes.page_phone} style={{width: '420px', borderRadius: '39.67px'}} alt=''/>
                    </Grid>
                </Grid>
                }
            </Grid>
            
        </Box>
    )
}

export default TheBulja8
