import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import thebulja_iconS from '../../images/thebulja/thebulja_icon_S.svg'
import page1BG from '../../images/thebulja/page1_bg.jpg'
import googleicon from '../../images/thebulja/BTN_google_play.svg'
import appstoreicon from '../../images/thebulja/BTN_app_store.svg'
import {analyticsEvent, searchEncoding} from "../../lib/common"
import {useLocation} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja1Styles = makeStyles({
    background: {
        backgroundImage: String.format('url({0})', page1BG),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#FFF',
        width: '100vw',
        height: '100vh',
        clear: 'both',
        position: 'static',
        overflow: 'hidden',
    },
    googlebtnicon: {
        backgroundImage: String.format('url({0})', googleicon),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionX: 'right',
        maxWidth: '700px', minWidth: '180px',
        height: '4.375vh',  
    },
    appstorebtnicon: {
        backgroundImage: String.format('url({0})', appstoreicon),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        maxWidth: '700px', minWidth: '180px',
        height: '4.375vh', 
    },
    appbutton: {
        display: 'flex',
        transform: 'scale(1) translateX(50%)',
        position: 'absolute',
        marginTop: '4.6875vh',
        right: '23.177%',
        
    },
    appbutton_m: {
        display: 'flex',
        transform: 'scale(1) translateX(50%)',
        position: 'absolute',
        marginTop: 'calc(4.6875vh + 12vh)',
        right: '50%',
        opacity: 0,

    },
    apptitle: {
        backgroundImage: String.format('url({0})', thebulja_iconS),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '9.375vw', height: '4.5833vh', minWidth: '180px', minHeight: '88px',
        marginTop: '4.6875vh',
        transform: 'translateX(-50%)',
        position: 'absolute',
        left: '18.229%',
        
    },
    apptitle_m: {
        backgroundImage: String.format('url({0})', thebulja_iconS),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '9.375vw', height: '4.5833vh', minWidth: '180px', minHeight: '88px',
        marginTop: '100px',
        transform: 'translateX(-50%)',
        position: 'absolute',
        left: '50%',
        
    },
    centertext: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '23.4375vh',
        width: '100%',
        height: '25vh',
        touchAction: 'none',
    },
    centertext_m: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '23.4375vh',
        width: '100%',
        height: '264px',
        touchAction: 'none',
    },
    page1_line: {
        height: '25vh',
    },
    page1_line2: {
        height: '264px',
    },
    blueButton: {
        backgroundColor: '#2E2F6CEE',
        width: '280px',
        height: '104px',
        borderRadius: '80px',
        position: 'absolute',
        bottom: '7.8125vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateX(-50%)',
        left: '50%',
    },
    blueButtonText: {
        fontSize: '34px',
        fontFamily: 'Noto Sans Korean',
        lineHeight: '49px',
        color: '#FFF',
        fontWeight: 700,
    }
})

const TheBulja1 = props => {
    const classes = TheBulja1Styles()
    const isss = useMediaQuery('(min-width:1281px)')
    const location = useLocation()

    const clickGooglePlay = () => {
        try {
            analyticsEvent('HOMEPAGE', 'GO_GOOGLEPLAY', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        } catch (e) {
            console.error(e)
        }

    }
    const clickAppleStore = () => {
        try {
            analyticsEvent('HOMEPAGE', 'GO_APPLESTORE', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        } catch (e) {
            console.error(e)
        }

    }

    const clickHandler = () => {
        analyticsEvent('HOMEPAGE', 'GO_DOWNLOAD', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        window.open(String.format('{0}{1}', 'https://thebulja.page.link/app', location.search?location.search:''), '_blank')
    }

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF'}}>
            <Grid container direction='column' className={classes.background} style={isss ? {} : {backgroundPositionX: '5%'}}>
                <Grid item className={isss ? classes.apptitle : classes.apptitle_m}/>
                <Grid item className={isss ? classes.appbutton : classes.appbutton_m}>
                    <a href={String.format('{0}{1}', 'https://play.google.com/store/apps/details?id=com.worksbe.thebulja', location.search?location.search.replace('?', '&'):'')} target='_blank' 
                    className={classes.googlebtnicon} onClick={clickGooglePlay} 
                    style={isss ? { width: '9.375vw'} 
                                : { width: '12vw'}}/>      
                    <Box style={{minWidth: '10px'}}/>   
                    <a href={String.format('{0}{1}', 'https://apps.apple.com/us/app/%EB%8D%94%EB%B2%8C%EC%9E%90-%EB%B6%84%EB%8B%B9%ED%8E%B8-%EC%9B%90%ED%81%B4%EB%A6%AD-%EB%A7%9E%EC%B6%A4-%EA%B5%AC%EC%9D%B8%EA%B5%AC%EC%A7%81-%EC%B5%9C%EC%A0%81%ED%99%94-%EB%A7%A4%EC%B9%AD/id1572610302', location.search?location.search:'')} target='_blank'  rel="noreferrer"
                       className={classes.appstorebtnicon} onClick={clickAppleStore}
                    style={isss ? { width: '9.375vw'} 
                                : { width: '12vw'}}/> 
                </Grid> 
                <Grid item className={isss ? classes.centertext : classes.centertext_m}>
                    {isss ?
                    <img src={require('../../images/thebulja/page1_center_line.svg').default} className={classes.page1_line} alt=''/>
                    :
                    <img src={require('../../images/thebulja/page1_center_line2.svg').default} className={classes.page1_line2} alt=''/>
                    }
                </Grid>   
                {!isss && 
                <Box>
                    <a href={String.format('{0}{1}', 'https://thebulja.page.link/app', location.search?location.search:'')} target='_blank'  rel="noreferrer" onClick={clickHandler}>
                        <Box className={classes.blueButton}>
                            <Typography className={classes.blueButtonText}>앱 다운로드</Typography>
                        </Box>
                    </a>
                </Box>
                }         
            </Grid>
        </Box>
    )
}

export default TheBulja1
