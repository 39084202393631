import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import CloseIcon from "../icons/CloseIcon";

const TermsVersionType = ({ handleClose, termsName, htmlName, setHtmlName, naviTitle }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (htmlName) {
      window.jQuery(document).ready(function () {
        window.jQuery("#txt").load(htmlName, function () {
          setLoading(false);
        });
      });
    }
  }, [htmlName]);

  const TermsNew = () => {
    setHtmlName(`${termsName}.html`);
  };
  const terms2021 = () => {
    setHtmlName(`${termsName}_2021_04_08.html`);
  };
  const terms2022 = () => {
    setHtmlName(`${termsName}_2022_03_02.html`);
  };
  const terms2023 = () => {
    if (termsName === '/txt/terms_PP') {
    setHtmlName(`${termsName}_2023_10_25.html`);
    } else {

    setHtmlName(`${termsName}_2023_10_18.html`);
    }
  };

  const [naviText, setNaviText] = useState(null);
  useEffect(() => {
    if (htmlName === `${termsName}_2021_04_08.html`) {
      setNaviText(`${naviTitle} 2021.4.8`);
    } else if (htmlName === `${termsName}_2022_03_02.html`) {
      setNaviText(`${naviTitle} 2022.3.2`);
    } else if (htmlName === `${termsName}_2023_10_18.html`) {
      setNaviText(`${naviTitle} 2023.10.18`);
    } else if (htmlName === `${termsName}_2023_10_25.html`) {
      setNaviText(`${naviTitle} 2023.10.25`);
    } else {
      setNaviText(naviTitle);
    }
  }, [htmlName]);
  const topHeight = 39 - 16;
  return (
    <Box className="overX posR">
      {loading && <LoadingCircle />}
      <Box
        item
        className="flexCC"
        style={{
          minHeight: "4.8rem",
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        <CloseIcon
          style={{
            margin: "0",
            color: "#202020",
          }}
          onClick={handleClose}
        ></CloseIcon>
        <Typography
          style={{
            fontSize: "1.8rem",
            lineHeight: "2.4rem",
            color: "#202020",
            fontWeight: 500,
            paddingLeft: "30px",
          }}
        >
          {naviText}
        </Typography>
      </Box>

      <Box className="scrollHiddenY" style={{ height: `calc(100vh - 4.8rem)` }}>
        <Box style={{ minHeight: "4.8rem" }} />
        {htmlName === `${termsName}.html` ? (
          <Box>
            <Box style={{ minHeight: "2.4rem" }} />
            <Typography className="black s14_14 w500">이전 개정이력 보기</Typography>
            <Box style={{ minHeight: "1.6rem" }} />
            <Box className="bottomBorderLine-sky" style={{ width: "7rem" }} onClick={terms2021}>
              <Typography className="sky027 s12_12 w300 textWS">버전 21.04.08</Typography>
            </Box>
            <Box style={{ minHeight: "1rem" }} />
            <Box className="bottomBorderLine-sky" style={{ width: "7rem" }} onClick={terms2022}>
              <Typography className="sky027 s12_12 w300 textWS">버전 22.03.02</Typography>
            </Box>
            {termsName !== '/txt/terms_location' && (
              <Fragment>
                <Box style={{ minHeight: "1rem" }} />
                <Box className="bottomBorderLine-sky" style={{ width: "7rem" }} onClick={terms2023}>
                  <Typography className="sky027 s12_12 w300 textWS">{termsName === '/txt/terms_PP' ? '버전 23.10.25' : '버전 23.10.18'}</Typography>
                </Box>
              </Fragment>)}
            <Box style={{ minHeight: "3rem" }} />
          </Box>
        ) : (
          <Box style={{ minHeight: "2.4rem" }} />
        )}

        <div id="txt" />
      </Box>
    </Box>
  );
};

export default TermsVersionType;
