import React,{useEffect} from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja2Styles = makeStyles({
    background: {      
        backgroundColor: '#EBF0EF', 
        display: 'flex',
        justifyContent: 'center',
        minHeight: '1004px',
        
    },
    center_box: {      
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: '73.4375vw',
        alignItems: 'center',
        minHeight: '1004px',
    },
    page2_img: {
        margin: '0 -7.1354vw 0 -3.28vw',
        width: '47.3958vw',
        minWidth: '910px',
    },
    page2_img_m: {
        display: 'flex',
        margin: '-146px 0 0 0',
        width: '639px',
    },
    text_box: {
        height: '100%',
        padding: '14.14vh 0 0 0',
        zIndex: 1,
    },
    text_box_m: {
        margin: '141px 0 0',
        zIndex: 1,
        
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '3.3333vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '3.3333vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',     
    },
})

const TheBulja2 = props => {
    const classes = TheBulja2Styles()
    const isss = useMediaQuery('(min-width:1281px)')

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF', position: 'relative'}}>
            {isss ?
            <Grid container className={classes.background}>
                <Grid container className={classes.center_box}>
                    <Grid item xs={6} className={classes.text_box}>
                        <Typography className={classes.text_light}
                        style={{textAlign: 'left'}}>더벌자는</Typography>
                        <Typography className={classes.text_light}
                        style={{textAlign: 'left'}}>구직자, 사장님 모두의</Typography>
                        <Typography className={classes.text_bold}
                        style={{textAlign: 'left'}}>더 벌 수 있는 기회를 </Typography>
                        <Typography className={classes.text_bold}
                        style={{display: 'flex', justifyContent: 'flex-start'}}>연결하는 서비스
                        <Typography className={classes.text_light}>입니다.</Typography></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <img  src={require('../../images/thebulja/page2_img.jpg')} className={classes.page2_img} alt='' />
                    </Grid>
                </Grid>
            </Grid>
            :
            <Grid container className={classes.background} style={{flexDirection: 'column', alignItems: 'center'}}>
                <Grid item className={classes.text_box_m}>
                    <Typography className={classes.text_light}
                    style={{textAlign: 'center', fontSize: '60px'}}>더벌자는</Typography>
                    <Typography className={classes.text_light}
                    style={{textAlign: 'center', fontSize: '60px'}}>구직자, 사장님 모두의</Typography>
                    <Typography className={classes.text_bold}
                    style={{textAlign: 'center', fontSize: '60px'}}>더 벌 수 있는 기회를 </Typography>
                    <Typography className={classes.text_bold}
                    style={{display: 'flex', fontSize: '60px', justifyContent: 'center'}}>연결하는 서비스
                    <Typography className={classes.text_light} style={{fontSize: '60px'}}>입니다.</Typography></Typography>
                </Grid>
                <Grid item>
                    <img  src={require('../../images/thebulja/page2_img.jpg')} className={classes.page2_img_m} alt='' />
                </Grid>
            </Grid>
            }
        </Box>
    )
}

export default TheBulja2
