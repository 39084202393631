import SvgIcon from "@material-ui/core/SvgIcon"
import React from "react"
import {withStyles} from "@material-ui/styles"
import clsx from "clsx"
import PropTypes from "prop-types"

const closeIconStyles = () => ({
    svg: {
        width: 19,
        height: 19,
    }
})

const CloseIcon = ({customColor, classes, ...rest}) =>
    <SvgIcon {...rest} viewBox="0 0 19 19" className={rest.className?clsx(classes.svg, rest.className):classes.svg}>
        <g fill="none" fillRule="evenodd">
            <g fill={customColor}>
                <g>
                    <path d="M18.565.448c.589.587.59 1.54.002 2.128l-.002.002L11.633 9.5l6.932 6.922c.589.587.59 1.54.002 2.129l-.002.001c-.589.588-1.543.588-2.133 0L9.5 11.63l-6.932 6.923c-.59.588-1.544.588-2.133 0-.589-.587-.59-1.54-.002-2.128l.002-.002L7.366 9.5.435 2.578C-.154 1.99-.155 1.038.433.449L.435.448c.589-.588 1.543-.588 2.133 0l6.931 6.921L16.432.448c.59-.588 1.544-.588 2.133 0z" transform="translate(-16 -39) translate(16 39)"/>
                </g>
            </g>
        </g>
    </SvgIcon>

CloseIcon.defaultProps = {
    customColor: '#202020'
}

CloseIcon.propTypes = {
    customColor: PropTypes.string,
}

export default withStyles(closeIconStyles)(CloseIcon)
