import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Landing from "./components/Landing/Landing";
import LandingFooter from "./components/Landing/Footer";
import TermsPP from "./components/Landing/TermsPP";
import TermsServiceBusiness from "./components/Landing/TermsServiceBusiness";
import TermsServiceIndividuals from "./components/Landing/TermsServiceIndividuals";
import TermsCompany from "./components/Landing/TermsCompany";
import TermsLocation from "./components/Landing/TermsLocation";
import storage from "./lib/storage";
import { analyticsEvent, onLive, searchEncoding } from "./lib/common";
import KakaoCustomerCenter from "./components/Landing/KakaoCustomerCenter";
import ReactGA from "react-ga4";
import TermsLanding from "./components/Landing/TermsLanding";

const App = (props) => {
  useEffect(() => {
    if (onLive()) {
      try {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (onLive()) {
      const cnt = storage.get("view_count");
      if (cnt) {
        storage.set("view_count", cnt + 1);
      } else {
        storage.set("view_count", 1);
        try {
          analyticsEvent("HOMEPAGE", "FIRST__LOADING", "HOMEPAGE");
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, []);
  const location = useLocation();
  useEffect(() => {
    try {
      analyticsEvent(
        "HOMEPAGE",
        "VIEW",
        String.format("MAIN|{0}|{1}", searchEncoding(location.pathname), searchEncoding(location.search))
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div className="App scrollHiddenY" id="content-wrap">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/landing" element={<Landing />} />
        {/*<Route path="/landing/worker/:other_id" element={<Landing/>}/>*/}
        <Route path="/landing/footer" element={<LandingFooter />} />
        <Route path="/landing/:other_id" element={<Landing />} />
        <Route path="/pp" element={<TermsPP />} />
        <Route path="/pp2" element={<TermsLanding />} />
        <Route path="/tos_business" element={<TermsServiceBusiness />} />
        <Route path="/tos_individual" element={<TermsServiceIndividuals />} />
        <Route path="/company" element={<TermsCompany />} />
        <Route path="/tol" element={<TermsLocation />} />
        <Route path="/customercenter" element={<KakaoCustomerCenter />} />
      </Routes>
    </div>
  );
};

export default App;
