import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import page3_line from '../../images/thebulja/page3_line.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja3Styles = makeStyles({
    background: {
        backgroundColor: '#CCD4D5',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: '1004px',
    },
    page3_img: {
        width: '52.0833vw',
        position: 'relative',
        marginRight: '55.9375vw',
    },
    page3_img_m: {
        width: '700px',
        position: 'relative',
        margin: '301px 155px 0 -135px',
    },
    text_box: {
        display: 'flex', alignItems: 'flex-end', 
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginRight: '17.7vw',
        position: 'absolute', right: 0,
        //height: '37.8125vh',
        transform: 'translateY(-50%)',
        top: '50%',
        zIndex: 1,
    },
    text_box_m: {
        display: 'flex', alignItems: 'flex-end', 
        flexDirection: 'column',
        position: 'absolute',
        marginRight: '48px',
        height: '684px',
        transform: 'translateY(-50%)',
        top: '50%',
        zIndex: 1,
    },
    page3_line: {
        backgroundImage: String.format('url({0})', page3_line),
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        width: '100vw',
        opacity: 1,
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',
         
    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',
        
    },
})

const TheBulja3 = props => {
    const classes = TheBulja3Styles()
    const isss = useMediaQuery('(min-width:1281px)')

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                <Grid item className={isss ? classes.text_box : classes.text_box_m}>
                <img src={isss ? require('../../images/thebulja/page3_red_box.svg').default : require('../../images/thebulja/page3_red_box2.svg').default} style={isss ? {width: '10.1042vw'} : {width: '233px'} } alt='' />
                
                                <Box style={isss ? {minHeight: '2.9688vh'} : {minHeight: '36px'}}/>
                    <Typography className={classes.text_light} 
                    style={isss ? {fontSize: '2.9vw'}
                                : {fontSize: '52px'}}>더 벌 수 있는 모든 기회를</Typography>
                    <Grid container className={classes.text_bold} 
                    style={isss ? {display: 'flex', fontSize: '2.9vw', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'nowrap'} 
                                : {display: 'flex', fontSize: '52px', flexDirection: 'column', textAlign: 'end'}}>
                                <Grid item className={classes.page3_line} style={isss ? {opacity: 1} : {opacity: 0}}/>
                                <Grid item className={classes.text_bold} style={isss ? {fontSize: '2.9vw'} : {fontSize: '52px'}}>한번에</Grid>
                                <Grid item className={classes.text_bold}/>&nbsp;찾아 드립니다.</Grid>
                    <Box style={isss ? {minHeight: '5.7813vh'} : {minHeight: '64px'}}/>
                    <img src={require('../../images/thebulja/arrow_Ellipse_icon.svg').default} style={isss ? {width: '3.5938vw'} : {width: '69px'}} alt='' />
                </Grid>
                <img src={require('../../images/thebulja/page3_img.jpg')} className={isss ? classes.page3_img : classes.page3_img_m} alt='' />
            </Grid>
    
            
        </Box>
    )
}

export default TheBulja3
