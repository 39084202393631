import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import TermsVersionType from "./TermsVersionType"

const TermsLanding = () => {
    const [htmlName, setHtmlName] = useState('txt/terms_PP.html')
    const navigate = useNavigate()
    const handleClose = () =>navigate(-1)
    return <TermsVersionType htmlName={htmlName} setHtmlName={setHtmlName} naviTitle={'서비스 이용약관(개인)'} termsName='terms_PP' handleClose={handleClose}/>
}

export default TermsLanding
