import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const TheBulja61Styles = makeStyles({
  background: {
    backgroundColor: "#FBFBFB",

    // minHeight: '1280px',
    // height: 'calc(100vh - 15.5469vh)',
  },
  center_box: {
    width: "100vw",
    // height: 'calc(100vh - 19.2969vh - 19.2969vh)',
    // minHeight: '1280px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "row",
    marginBottom: "27.1875vh",
  },
  center_box_m: {
    minHeight: "1280px",
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "column",
    flexFlow: "column",
    // marginBottom: '76px',
  },
  text_box: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  text_box_m: {
    //position: 'absolute',
    left: "0%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    whiteSpace: "nowrap",
  },
  text_box2: {
    display: "flex",
    alignItems: "center",
    padding: "2.3438vh 2.1875vw 2.6563vh 1.7188vw ",
    borderRadius: "1.5625vh",
    boxShadow: "0.2vw 0.3vh 60px #20202022 ",
  },
  text_box2_m: {
    padding: "34px 27px 34px 36px",
    borderRadius: "20px",
    boxShadow: "0.2vw 0.3vh 60px #20202022 ",
  },
  text_light: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 300,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_bold: {
    fontFamily: "Noto Sans Korean",
    fontSize: "2.3vw",
    lineHeight: "126%",
    fontWeight: 700,
    color: "#333375",
    letterSpacing: "-2px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
  text_grey_medium: {
    fontFamily: "Noto Sans Korean",
    fontSize: "1.1458vw",
    lineHeight: "126%",
    fontWeight: 600, //400과 500은 피그마와 달리 웹에서는 구분이 안되서 이부분 600 처리
    color: "#5F5F69",
    letterSpacing: "-1px",
    // whiteSpace: 'nowrap',
    position: "relative",
    display: "flex",
  },
  text_grey_regular: {
    fontFamily: "Noto Sans Korean",
    fontSize: "1.1458vw",
    lineHeight: "126%",
    fontWeight: 400,
    color: "#5F5F69",
    letterSpacing: "-1px",
    // whiteSpace: 'nowrap',
    position: "relative",
  },
});

const TheBulja61 = (props) => {
  const classes = TheBulja61Styles();
  const isss = useMediaQuery("(min-width:1281px)");

  return (
    <Box style={{ width: "100vw", backgroundColor: "#FFF", position: "relative" }}>
      <Grid container direction="column" className={classes.background}>
        <Grid container className={isss ? classes.center_box : classes.center_box_m}>
          <Grid item className={isss ? classes.text_box : classes.text_box_m}>
            <img
              src={require("../../images/thebulja/page6_1_red_box.svg").default}
              style={
                isss ? { margin: "14.375vh 0 2.8906vh", width: "6.77vw" } : { margin: "160px 0 37px", width: "156px" }
              }
              alt=""
            />
            <Typography className={classes.text_light} style={isss ? {} : { fontSize: "46px" }}>
              맞춤형 조합을 통해 지금보다
            </Typography>
            <Grid style={isss ? { display: "flex" } : {}}>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px" }}>
                더 많은 일자리 기회를
              </Typography>
              <Typography className={classes.text_bold} style={isss ? {} : { fontSize: "46px", textAlign: "center" }}>
                &nbsp;얻을 수 있습니다.
              </Typography>
            </Grid>
            <Box style={isss ? { minHeight: "8.0469vh" } : { minHeight: "15px" }} />
          </Grid>

          <Grid item>
            <img
              src={require("../../images/thebulja/page6_1_text.svg").default}
              style={isss ? { width: "10.3125vw", margin: "0 0 1.4063vh" } : { width: "240px", marginTop: "78px" }}
            />
          </Grid>
          <Grid item>
            <img
              src={require("../../images/thebulja/page6_1_icon_S.svg").default}
              style={isss ? { display: "none" } : { width: "240px" }}
            />
          </Grid>
          <Grid item className={isss ? classes.text_box2 : classes.text_box2_m}>
            <Grid>
              <img
                src={require("../../images/thebulja/page6_1_icon_SS.svg").default}
                style={isss ? { height: "10vh", marginRight: "1.25vw" } : { display: "none" }}
              />
            </Grid>
            <Grid>
              <Typography
                className={classes.text_grey_medium}
                style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
              >
                - 희망지역 :
                <Typography
                  className={classes.text_grey_regular}
                  style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
                >
                  &nbsp;분당 서현역 인근
                </Typography>
              </Typography>
              <Typography
                className={classes.text_grey_medium}
                style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
              >
                - 근무가능시간 :
                <Typography
                  className={classes.text_grey_regular}
                  style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
                >
                  &nbsp;월, 목 15시~21시 / 금 9시~13시 / 주말 10시~22시
                </Typography>
              </Typography>
              <Typography
                className={classes.text_grey_medium}
                style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
              >
                - 희망지역 :
                <Typography
                  className={classes.text_grey_regular}
                  style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
                >
                  &nbsp;월 150만원 이상
                </Typography>
              </Typography>
              <Typography
                className={classes.text_grey_medium}
                style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
              >
                - 희망업종 :
                <Typography
                  className={classes.text_grey_regular}
                  style={isss ? { lineHeight: "3vh" } : { fontSize: "22px", lineHeight: "44px" }}
                >
                  &nbsp;매장스텝/판매, 편의점/매점 매장관리
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            position: "absolute",
            bottom: isss ? "14.4531vh" : "50px",
          }}
        >
          <img
            src={require("../../images/thebulja/dot_red.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
          <Box style={isss ? { width: "0.7813vw" } : { width: "10px" }} />
          <img
            src={require("../../images/thebulja/dot_grey.svg").default}
            style={isss ? { height: "1.0938vh" } : { height: "14px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TheBulja61;
