import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import googleicon from '../../images/thebulja/BTN_google_play.svg'
import appstoreicon from '../../images/thebulja/BTN_app_store.svg'
import {analyticsEvent, searchEncoding} from "../../lib/common"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useLocation} from "react-router-dom"



const TheBulja11Styles = makeStyles({
    background: {
        backgroundColor: '#64647A',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
    },
    page11_img: {
        width: '62.5vw',
        margin: '-215px 140px -505px 0',
    },
    page11_img_m: {
        width: '720px',
        margin: 0,
    },
    text_box: {
        position: 'absolute',
        transform: 'translateX(-50%)',
        left: '50%',
        marginBottom: '1.7188vh',
    },
    text_box_m: {
        position: 'absolute',
        transform: 'translateX(-50%)',
        left: '50%',
        textAlign: 'center',
    },
    googlebtnicon: {
        backgroundImage: String.format('url({0})', googleicon),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionX: 'right',
        maxWidth: '700px', minWidth: '180px',
        height: '4.375vh',  
    },
    appstorebtnicon: {
        backgroundImage: String.format('url({0})', appstoreicon),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        maxWidth: '700px', minWidth: '180px',
        height: '4.375vh', 
    },
    end_page_appbutton: {
        display: 'flex',
        justifyContent: 'center',
    },
    button_bar: {
        position: 'absolute',
        top: 0,
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '3.9063vh 17.7083vw 0', 
    },
    button_bar_m: {
        position: 'absolute',
        top: 0,
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        display: 'flex',
        justifyContent: 'center',
        margin: '3.9063vh 17.7083vw 0', 
    },
    page11_red_button: {
        width: '5.2083vw',
    },
    page11_red_button_m: {
        width: '128px',
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',  
    },

})

const TheBulja11 = props => {
    const classes = TheBulja11Styles()
    const isss = useMediaQuery('(min-width:1281px)')
    const location = useLocation()

    const clickGooglePlay = () => {
        try {
            analyticsEvent('HOMEPAGE', 'GO_GOOGLEPLAY', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        } catch (e) {
            console.error(e)
        }

    }
    const clickAppleStore = () => {
        try {
            analyticsEvent('HOMEPAGE', 'GO_APPLESTORE', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        } catch (e) {
            console.error(e)
        }

    }

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#64647A', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                <Grid item className={isss ? classes.text_box : classes.text_box_m}> 
                    <Typography className={classes.text_light} style={isss ? {textAlign: 'center'} : {fontSize: '60px', textAlign: 'center'}}>단, 한 클릭만으로!</Typography>
                    <Grid style={isss ? {display: 'flex'} : {}}>
                        <Typography className={classes.text_bold} style={isss ? {whiteSpace: 'pre'} : {fontSize: '60px'}}>기회를 연결하는 </Typography>
                        <Typography className={classes.text_bold} style={isss ? {} :{fontSize: '60px', textAlign: 'center'}}>가장 쉬운 방법</Typography>
                    </Grid>
                    <Box style={isss ? {minHeight: '7.1875vh'} : {}}/>
                    
                    <Grid item className={classes.end_page_appbutton} style={isss ? {} : {display: 'none'}}>
                        <a href={String.format('{0}{1}', 'https://play.google.com/store/apps/details?id=com.worksbe.thebulja', location.search?location.search.replace('?', '&'):'')} target='_blank'  rel="noreferrer"
                           className={classes.googlebtnicon} onClick={clickGooglePlay}
                        style={isss ? { width: '9.375vw'} 
                                    : {}}/>      
                        <Box style={{minWidth: '10px'}}/>   
                        <a href={String.format('{0}{1}', 'https://apps.apple.com/us/app/%EB%8D%94%EB%B2%8C%EC%9E%90-%EB%B6%84%EB%8B%B9%ED%8E%B8-%EC%9B%90%ED%81%B4%EB%A6%AD-%EB%A7%9E%EC%B6%A4-%EA%B5%AC%EC%9D%B8%EA%B5%AC%EC%A7%81-%EC%B5%9C%EC%A0%81%ED%99%94-%EB%A7%A4%EC%B9%AD/id1572610302', location.search?location.search:'')} target='_blank'  rel="noreferrer"
                           className={classes.appstorebtnicon} onClick={clickAppleStore}
                        style={isss ? { width: '9.375vw'} 
                                    : {}}/> 
                    </Grid> 
                </Grid>
                <Grid item className={isss ? classes.button_bar : classes.button_bar_m}>
                    <Grid style={isss ? {display: 'flex'} : {display: 'flex', marginRight: '140px'}}>
                        <img src={require('../../images/thebulja/page11_red1.svg').default} className={isss? classes.page11_red_button : classes.page11_red_button_m}/>
                        <Box style={isss ? {width: '0.4167vw'} : {width: '8px'}}/>
                        <img src={require('../../images/thebulja/page11_red2.svg').default} className={isss? classes.page11_red_button : classes.page11_red_button_m}/>   
                    </Grid>
                    <Grid className={classes.text_bold} style={isss ? {alignSelf: 'center', fontSize: '1.875vw', color: '#FF6262', letterSpacing: '-1px'} : {alignSelf: 'center', fontSize: '26px', color: '#FF6262', letterSpacing: '-1px'}}>Employment 3.0</Grid>
                </Grid>
                <Grid item style={{display: 'flex', overflow: 'hidden', justifyContent: 'center'}}><img src={require('../../images/thebulja/page11_img.jpg')} className={isss ? classes.page11_img : classes.page11_img_m}/></Grid> 
            </Grid>
        </Box>
    )
}

export default TheBulja11
