import React from 'react'
import TheBulja0 from "./TheBulja0"
import TheBulja1 from "./TheBulja1"
import TheBulja2 from "./TheBulja2"
import TheBulja3 from "./TheBulja3"
import TheBulja4 from "./TheBulja4"
import TheBulja5 from "./TheBulja5"
import TheBulja61 from "./TheBulja61"
import TheBulja62 from "./TheBulja62"
import TheBulja63 from "./TheBulja63"
import TheBulja7 from "./TheBulja7"
import TheBulja8 from "./TheBulja8"
import TheBulja9 from "./TheBulja9"
import TheBulja10 from "./TheBulja10"
import TheBulja11 from "./TheBulja11"
import Footer from "./Footer"

const Landing = props => {
    return (
        <React.Fragment>
            <TheBulja0/>
            <TheBulja1/>
            <TheBulja2/>
            <TheBulja3/>
            <TheBulja4/>
            <TheBulja5/>
            <TheBulja61/>
            <TheBulja62/>
            <TheBulja63/>
            <TheBulja7/>
            <TheBulja8/>
            <TheBulja9/>
            <TheBulja10/>
            <TheBulja11/>
            <Footer/>
        </React.Fragment>
    )
}

export default Landing
