import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import page3_line from '../../images/thebulja/page3_line.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja7Styles = makeStyles({
    background: {
        backgroundColor: '#CCD4D5',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: '1004px',
    },
    page7_img: {
        width: '52.2917vw',
        position: 'relative',
        margin: '0 55.9375vw 0 0',
    },
    page7_img_m: {
        width: '540px',
        position: 'relative',
        margin: '301px 0 0 180px',
    },
    text_box: {
        display: 'flex', alignItems: 'flex-end', 
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginRight: '17.7vw',
        position: 'absolute', right: 0,
        height: '37.8125vh',
        transform: 'translateY(-50%)',
        top: '50%',
        zIndex: 1,
    },
    text_box_m: {
        display: 'flex', alignItems: 'flex-start', 
        flexDirection: 'column',
        position: 'absolute', left: 0,
        marginLeft: '48px',
        height: '684px',
        transform: 'translateY(-50%)',
        top: '50%',
        zIndex: 1,
    },
    page3_line: {
        backgroundImage: String.format('url({0})', page3_line),
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        width: '100vw',
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.9vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',    
        position: 'relative',
    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.9vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#FFF',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',
        
    },
})

const TheBulja7 = props => {
    const classes = TheBulja7Styles()
    const isss = useMediaQuery('(min-width:1281px)')

    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                <Grid item className={isss ? classes.text_box : classes.text_box_m}>
                    <img src={isss ? require('../../images/thebulja/page7_red_box.svg').default : require('../../images/thebulja/page7_red_box2.svg').default} style={isss ? {width: '10.1042vw'} : {width: '233px'}} alt='' />
                    <Box style={isss ? {minHeight: '2.9688vh'} : {minHeight: '36px'}}/>
                    <Typography className={classes.text_light}    style={isss ? {} : {fontSize: '52px'}}>{isss && '더'}믿고 신뢰할 수 있는 인재를</Typography>
                    <Grid container className={classes.text_bold} style={isss ? {display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'nowrap'} 
                                                                              : {display: 'flex', flexDirection: 'column', textAlign: 'start', fontSize: '52px'}}>
                        <Grid item className={classes.text_bold}  style={isss ? {} : {fontSize: '52px'}}>{isss ? '쉽고, 빠르게' : '가장 쉽고, 빠르게'}&nbsp;</Grid>
                        <Grid item className={classes.text_bold}  style={isss ? {} : {fontSize: '52px'}}>채용할 수 있게</Grid>
                    </Grid>
                    {isss ?
                    <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'nowrap'}} >
                        <Grid item className={classes.page3_line}/>
                        <Grid item className={classes.text_bold}>&nbsp;안내합니다.</Grid>
                    </Grid>
                    :
                    <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'nowrap'}} >
                        <Grid item className={classes.text_bold} style={{fontSize: '52px'}}>안내합니다.</Grid>
                    </Grid>
                    } 
                    <Box style={isss ? {minHeight: '5.7813vh'} : {minHeight: '64px'}}/>
                    <img src={require('../../images/thebulja/arrow_Ellipse_icon.svg').default} style={isss ? {width: '3.5938vw'} : {width: '69px'}} alt='' />
                </Grid>
                {isss ?
                <img src={require('../../images/thebulja/page7_img2.jpg')} className={classes.page7_img} alt='' />
                :
                <img src={require('../../images/thebulja/page7_img.jpg')} className={classes.page7_img_m} alt='' />
                }
            </Grid>      
        </Box>
    )
}

export default TheBulja7
