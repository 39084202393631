import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "../icons/CloseIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { analyticsEvent, searchEncoding } from "../../lib/common";

const termsLocationStyles = () => ({
  body: {
    //position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1110,
    backgroundColor: "#fff",
  },
  closeButton: {
    margin: "-3px 20px 20px",
    color: "#202020",
  },
  title: {
    fontSize: 18,
    lineHeight: "24px",
    color: "#202020",
    fontWeight: 500,
    //position: 'absolute',
    marginTop: -6,
    paddingLeft: 17,
  },
  texttitle: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 17,
    color: "#202020",
  },
  textsub: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 32,
    color: "#202020",
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 17,
  },
  text2: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 12,
  },
  text3: {
    fontSize: 12,
    fontWeight: 400,
  },
  maintext: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 17,
    lineHeight: "17px",
    textAlign: "left",
    position: "absolute",
    top: "56px",
    width: "calc(100% - 32px)",
    color: "#747474",
  },
});

const TermsLocation = (props) => {
  const { classes } = props;
  const topHeight = 39 - 16;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    try {
      analyticsEvent("HOMEPAGE", "VIEW", String.format("TERMS_LOCATION_{0}", searchEncoding(location.search)));
    } catch (e) {
      console.error(e);
    }
  }, []);
  const handleCloseDefault = () => navigate(-1);

  return (
    <Grid container direction="column" className={classes.body}>
      <Grid item style={{ marginTop: topHeight, display: "flex" }}>
        <CloseIcon className={classes.closeButton} onClick={handleCloseDefault}></CloseIcon>
        <Typography className={classes.title}>위치서비스 이용약관</Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item className={classes.maintext}>
            <Typography style={{ height: 0 }} />
            <Typography className={classes.texttitle}>위치서비스 이용약관</Typography>

            <Typography className={classes.textsub}>제1조 (목적)</Typography>
            <Typography className={classes.text}>
              본 약관은 (주)웍스비(이하 “회사")가 제공하는 위치기반 서비스를 이용함에 있어 “회사"와 회원의 권리, 의무 및
              책임사항을 규정함을 목적으로 합니다.
            </Typography>

            <Typography className={classes.textsub}>제2조 (이용약관의 효력 및 변경)</Typography>
            <Typography className={classes.text}>
              1. 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 “회사"가 정한 소정의 절차에
              따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
            </Typography>
            <Typography className={classes.text}>
              2. 회원이 온라인에서 본 약관의 “동의하기" 버튼 또는 동의 체크, 또는 확인 체크 또는 버튼을 클릭하였을 경우,
              본 약관의 모든 내용을 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 [위치정보의 보호 및 이용 등에 관한 법률], [콘텐츠산업진흥법], [전자상거래 등에서의 소비자
              보호에 관한 법률], [소비자기본법], [약관의 규제에 관한 법률] 등 관련법령을 위배하지 않는 범위 내에서 본
              약관을 개정할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"가 약관을 개정할 경우에는 그 개정 내용과 적용일자를 명시하여, 현행약관과 함께 그 개정약관의
              적용일자 7일 전부터 적용일자 전일까지 공지합니다. 단, 회원의 권리 및 의무에 중대한 영향을 주는 변경의
              경우에는 적용일자 30일 전부터 공지하도록 합니다.
            </Typography>
            <Typography className={classes.text}>
              5. 회원은 변경된 약관에 대해 거부할 권리가 있습니다. “회원"은 변경된 약관이 공지된지 15일 이내에
              거부의사를 표명할 수 있고, 회원이 거부하는 경우 “회사"는 15일의 기간을 정하여 회원에게 사전 통지 후 해당
              회원과의 계약을 해지할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              6. 만약 회원이 변경된 약관에 대해 거부의사를 표시하지 않거나, 제4항에 따른 시행일 이후에 서비스를 이용하는
              경우에는 동의한 것으로 간주합니다.
            </Typography>

            <Typography className={classes.textsub}>제3조 (관계법령의 적용)</Typography>
            <Typography className={classes.text}>
              본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대해서는 관계법령
              또는 상관례를 따릅니다.
            </Typography>

            <Typography className={classes.textsub}>제4조 (서비스의 내용)</Typography>
            <Typography className={classes.text}>1. 서비스의 세부 내용은 아래와 같습니다.</Typography>
            <Typography className={classes.text2}>1) 위치 정보를 활용하여, 구인정보 또는 인재정보 제시</Typography>
            <Typography className={classes.text2}>
              2) 위치 정보를 활용하여, 특정 위치를 지도 화면에 표시하여 구인정보 또는 인재정보 제시
            </Typography>
            <Typography className={classes.text2}>
              &nbsp;&nbsp;&nbsp;&nbsp;(단, 사용자의 구체적인 실제 현 위치 좌표를 표시하지는 않음)
            </Typography>
            <Typography className={classes.text2}>
              3) 위치정보를 기반으로 기반으로 구인정보 또는 인재정보, 기타 회원이 서비스에서 제공하는 매칭
            </Typography>
            <Typography className={classes.text2}>4) 기타 현 위치를 이용한 게시물 및 데이터 검색결과 제시</Typography>
            <Typography className={classes.text2}>
              5) 기타 위치 정보를 활용한 타겟 광고 및 타겟 서비스 정보 제시
            </Typography>
            <Typography className={classes.text2}>6) 위치정보를 활용한 서비스 개선 및 기능, 상품 개발</Typography>
            <Typography className={classes.text}>
              2. 서비스는 연중무휴, 1일 24시간 이용을 원칙으로 합니다. 단, 회사의 업무 또는 기술상, 경영상, 또는
              운영상의 이유로 서비스가 일시 중지될 수 있습니다. 이때 회사는 그 중단 기간을 사전 또는 사후에 공지할 수
              있습니다.
            </Typography>

            <Typography className={classes.textsub}>제5조 (서비스 이용 요금)</Typography>
            <Typography className={classes.text}>1. 본 약관에 따른 서비스는 기본적으로 무료입니다.</Typography>
            <Typography className={classes.text}>
              2. 전항에도 불구하고, 만약 “회사"가 별도의 유료 서비스를 제공하는 경우, “회사"는 그 유료 서비스의 내용 및
              이용요금, 사용기간 또는 횟수, 범위를 공지합니다. 회원은 해당 유료서비스를 이용하고자 할 경우, 공지된
              이용요금을 지불해야 사용 가능합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회원의 가입 유형에 따라, “회사"의 [서비스
              이용약관(개인)] 또는 [서비스 이용약관(기업)]의 이용요금 결제 및 환불에 관한 규정을 따릅니다.
            </Typography>
            <Typography className={classes.text}>
              4. 회원의 개인정보도용 및 결제사기, 결제수단 및 그 정보의 분실 및 관리 부주의 등으로 인한 환불요청,
              결제자의 개인정보 요구는 관계 법령에 별도로 정함이 있는 경우를 제외하고는 거절될 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              5. 무선 서비스 이용 시 발생하는 데이터 통신료 또는 MMS 등으로 서비스를 이용하는 경우 발생하는 데이터
              통신료 및 요금은 별도이며, 회원이 가입한 각 이동통신사의 정책에 따릅니다.
            </Typography>

            <Typography className={classes.textsub}>제6조 (서비스 내용 변경 통지 등)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 서비스의 운영 상 필요하다고 판단하는 경우 서비스의 내용을 변경하거나 종료할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 서비스 내용 변경을 회원에게 통지하며, 그 통지수단으로 회원이 등록한 연락처, 또는 서비스 내
              공지사항 및 알림, SNS, SMS, “회사” 홈페이지 등 중 어느 하나 이상을 선택하여 통지합니다.
            </Typography>

            <Typography className={classes.textsub}>제7조 (서비스 이용의 제한 및 중지)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 다음 각 호의 어느 하나에 해당하는 사유가 발생하는 경우, 해당 회원의 서비스 이용을 제한하거나
              중지시킬 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 회원이 서비스의 원활한 운영을 고의 또는 중과실로 방해하는 경우
            </Typography>
            <Typography className={classes.text2}>
              2) 국가비상상태, 천재지변, 서비스 설비의 장애, 서비스 이용 폭주 증으로 서비스 이용에 지장이 있는 때
            </Typography>
            <Typography className={classes.text2}>
              3) 회원이 서비스 이용 시 사용하는 단말에서 위치정보를 제공하지 않기로 설정한 경우
            </Typography>
            <Typography className={classes.text2}>
              4) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
            </Typography>
            <Typography className={classes.text2}>
              5) 서비스용 설비 점검, 보수, 공사 등으로 인한 부득이한 경우
            </Typography>
            <Typography className={classes.text2}>
              6) 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
            </Typography>
            <Typography className={classes.text}>
              2. 전항에 의해 회원의 서비스 이용을 제한하거나 중지시킬 경우, 회사는 그 사유 및 제한기간 등을 회원에게
              알려야 합니다.
            </Typography>

            <Typography className={classes.textsub}>제8조 (개인위치정보의 이용 또는 제공)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 서비스 제공을 위하여 회원의 위치정보를 이용할 수 있으며, 회원은 본 약관에 동의함으롰 이에
              동의한 것으로 봅니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 타 사업자 또는 타 이용고객과의 요금정산 및 민원처리를 위해 위치정보 이용 및 제공사실
              확인자료(“회원"의 가장 최근 사용 기록 기준)를 자동 기록, 보존하며, 해당 자료는 1년간 보관합니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 개인위치정보주체의 동의 없이 개인위치정보를 제3자에게 아니하며, 제3자 제공 서비스를 제공하는
              경우에는 제공받는 자 및 제공목적을 사전에 개인위치정보주체에게 고지하거나 [개인정보처리방침] 개정 또는
              서비스 내 공지사항을 통해 이를 알립니다. 단, 다음 각 호의 어느 하나에 해당하는 경우는 예외로 합니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            </Typography>
            <Typography className={classes.text2}>
              2) 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위한 확인자료가 필요한 경우
            </Typography>
            <Typography className={classes.text2}>
              3) 통계작성, 학술연구 또는 서비스 개발 및 소개, 기타 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로
              가공하여 제공하는 경우
            </Typography>
            <Typography className={classes.text}>
              4. “회사"가 개인위치정보를 회원이 동의한 제3자에게 제공하는 경우, 개인위치정보를 수집한 해당 단말장치 또는
              서비스 내 알림, 회원이 등록한 연락처 중 하나로 회원에게 제공받는 자, 일시 및 목적을 통보합니다. 단, 서비스
              내 알림 및 온라인 게시 등의 방법으로 통지하는 경우는 회원이 그 방법을 통해 통지받는 것에 대해 사전에
              서비스 내 절차 또는 “회사"의 [서비스 이용 약관]에 동의하는 절차를 통해 동의 의사를 표시했을 경우에
              한합니다.
            </Typography>

            <Typography className={classes.textsub}>제9조 (개인위치정보주체의 권리)</Typography>
            <Typography className={classes.text}>
              1. 회원은 "회사"에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자
              제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 "회사"는 위치정보 이용·제공사실 확인자료를
              파기하여야 합니다.
            </Typography>
            <Typography className={classes.text}>
              2. 회원은 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 “회사"에 요구할 수 있으며, “회사"는
              이를 수락하며, 이를 위한 기술적 수단을 갖추고 있어야 합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 회원은 “회사"에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있으며, 당해 자료에 오류가
              있는 경우 그 정정을 요구할 수 있습니다. 이 경우 “회사"는 정당한 사유가 없는 한 “회원"의 요구를 거절할 수
              없습니다.
            </Typography>
            <Typography className={classes.text2}>1) 본인에 대한 위치정보 이용, 제공사실 확인자료</Typography>
            <Typography className={classes.text2}>
              2) 본인의 개인위치정보가 [위치정보의 보호 및 이용 등에 관한 법률] 또는 다른 법률 규정에 의하여 제3자에게
              제공된 이유 및 내용
            </Typography>
            <Typography className={classes.text}>
              4. 회원은 제1호 내지 제3호의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제10조 (법정대리인의 권리)</Typography>
            <Typography className={classes.text}>
              1. “회사"의 서비스는 만 15세 미만의 아동은 이용할 수 없습니다.
            </Typography>
            <Typography className={classes.text}>
              2. 만약 전항에도 불구하고 만 15세 미만의 아동이 서비스를 이용하고자 할 경우, 그 법정대리인의 동의를 구해야
              합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 전항에 의해 가입한 만 15세 미만의 회원으로부터 제8조에 따른 개인위치정보를 “회사"가 이용 또는
              제공하고자 하는 경우, 가입시 회원이 “회사"에 통지한 그 법정대리인의 동의를 받아야 합니다.
            </Typography>
            <Typography className={classes.text}>
              4. 제9조의 규정은 제2항 및 제3항에 따른 법정대리인이 동의를 하는 경우에 이를 준용하며, 이 경우 그
              법정대리인을 회원으로 봅니다.
            </Typography>
            <Typography className={classes.text}>
              5. 본조의 규정은 회원이 금치산자이거나 [장애인복지법] 제2조 제2항 제2호에 따른 정신적 장애를 가진 자로서
              [장애인고용촉진 및 직업재활법] 제2조 제2호의 규정에 의한 중증장애인에 해당되는 자일 경우에도 동일하게
              적용합니다.
            </Typography>

            <Typography className={classes.textsub}>제11조 (위치정보관리책임자의 지정)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 위치정보를 적절히 관리, 보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인
              책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
            </Typography>
            <Typography className={classes.text}>
              2. 위치정보관리책임자에 대한 구체적인 사항은 본 약관의 부칙에 따릅니다.
            </Typography>

            <Typography className={classes.textsub}>제12조 (양도금지)</Typography>
            <Typography className={classes.text}>
              회원은 서비스를 받을 권리 및 본 약관 상의 의무를 타인에게 양도 내지 증여하거나 대여, 담보제공 등의
              목적으로 처분할 수 없습니다.
            </Typography>

            <Typography className={classes.textsub}>제13조 (손해배상 및 면책)</Typography>
            <Typography className={classes.text}>
              1. “회사"가 [위치정보의 보호 및 이용 등에 관한 법률] 제15조 내지 제26조의 규정을 위반한 행위로 회원에게
              손해가 발생하였으며, 회원의 시정요청에 대하여 “회사"가 적절히 응대하지 못할 경우, 회원은 “회사"에 대하여
              손해배상 청구를 할 수 있습니다. 이 경우, “회사"에게 고의 및 과실이 없음을 입증할 책임은 “회사"에게
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회원"이 본 약관의 규정 또는 “회사"가 서비스 이용 시 동의를 요청하는 [서비스 이용 약관(개인)] 또는
              [서비스 이용약관(기업)]의 규정을 위반하여 “회사"에 손해가 발생한 경우, 회사는 회원에 대하여 손해배상을
              청구 할 수 있습니다. 이 경우, 회원 본인에게 고의 및 과실이 없음을 입증할 책임은 회원에게 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. 회사는 서비스 및 서비스에 게재된 정보, 자료, 검색 또는 매칭 결과, 사실의 신뢰도 및 정확성 등에 대해서
              보증을 하지 않으며, 이로 인해 발생한 회원의 손해에 대하여 책임을 지지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 다음 각호 중 어느 하나의 사유로 서비스를 제공할 수 없는 경우, 이로 인하여 회원에게 발생하는
              손해에 대하여 책임을 부담하지 않습니다.
            </Typography>
            <Typography className={classes.text2}>1) 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</Typography>
            <Typography className={classes.text2}>
              2) 설비 장애, 보수, 공사 및 제7조 제1항에 규정된 상태가 있는 경우
            </Typography>
            <Typography className={classes.text2}>3) 회원의 귀책사유로 서비스 이용에 장애가 있는 경우</Typography>
            <Typography className={classes.text2}>4) 기타 회사의 고의 또는 중과실이 없는 사유로 인한 경우</Typography>

            <Typography className={classes.textsub}>제14조 (분쟁의 조정 및 기타)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 회원으로부터 제기되는 불만사항 및 의견을 가능한 신속하게 처리하기 위해 노력합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"와 회원은 서비스와 관련하여 발생하는 분쟁을 상호 원만하게 해결하기 위하여 필요한 모든 노력을
              하여야 합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 전항에서 당사자 간 협의가 이루어지지 않은 경우 “회사"와 회원은 [위치정보의 보호 및 이용 등에 관한 법률]
              제28조의 규정에 의한 방송통신위원회에 재정을 신청하거나, [개인정보보호법] 제43조의 규정에 의한
              개인정보분쟁조정위원회에 재정을 신청할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. 전항의 노력에도 불구하고, 협의에 의해 해결되지 않아 소송이 제기 되야 하는 경우 동 분쟁에 관한 소송은
              “회사"의 소재지 관할법원으로 합니다.
            </Typography>

            <Typography className={classes.textsub}>제15조 (회사의 주소 및 연락처)</Typography>
            <Typography className={classes.text}>회사의 상호 및 주소 등은 다음과 같습니다.</Typography>
            <Typography className={classes.text}>주식회사 웍스비</Typography>
            <Typography className={classes.text3}>대표 김 현 호</Typography>
            <Typography className={classes.text3}>서울시 서초구 매헌로16길 32, 301호</Typography>
            <Typography className={classes.text3}>사업자등록번호 122-86-47366</Typography>
            <Typography className={classes.text3}>직업정보제공사업신고번호: 서울청 제2023-9호</Typography>
            <Typography className={classes.text3}>통신판매업신고번호: 제 2023-서울서초-1611호</Typography>
            <Typography className={classes.text3}>고객센터 help@worksbe.com, 1644-2163</Typography>

            <Typography className={classes.texttitle} style={{ marginTop: "49px" }}>
              부칙
            </Typography>

            <Typography className={classes.textsub}>제1조 (시행일)</Typography>
            <Typography className={classes.text}>
              1. 본 약관은 2021년 4월 8일부터 시행합니다.
              <br />
              2. 본 약관은 2022년 3월 2일 개정되었으며, 2022년 3월 9일부터 시행합니다.
              <br />
              3. 본 약관은 2023년 10월 17일 개정되었으며, 2023년 10월 25일부터 시행합니다.
            </Typography>

            <Typography className={classes.textsub}>제2조 (위치정보관리책임자)</Typography>
            <Typography className={classes.text}>
              1. 본 약관 제11조에 따른 “회사"의 위치정보관리책임자는 다음과 같습니다.
            </Typography>
            <Typography className={classes.text2}>이름 : 김현호</Typography>
            <Typography className={classes.text2}>소속/지위 : 대표이사</Typography>
            <Typography className={classes.text2}>이메일 : privacy@worksbe.com</Typography>
            <Typography className={classes.text2} style={{ marginBottom: "32px" }}>
              대표번호 : 1644-2163
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(termsLocationStyles)(TermsLocation);
