import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import page6_1_text from '../../images/thebulja/page6_1_text.svg'
import page6_1_icon_S from '../../images/thebulja/page6_1_icon_S.svg'
import page6_1_icon_SS from '../../images/thebulja/page6_1_icon_SS.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja9Styles = makeStyles({
    background: {
        backgroundColor: '#FBFBFB',
        minHeight: '1280px',
        // minHeight: '1280px',
        // height: 'calc(100vh - 15.5469vh)',
    },
    center_box: {
        width: 'calc(100vw - 14.5vw - 14.5vw)',
        // height: 'calc(100vh - 19.2969vh - 19.2969vh)',
        // minHeight: '1280px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
        flexFlow: 'row',
        marginBottom: '14.7656vh',
        
    },
    center_box_m: {
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        // height: '100vh',
        // minHeight: '1280px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
        flexFlow: 'column',
        marginBottom: '202px',
        
    },
    page6_1_text: {
        backgroundImage: String.format('url({0})', page6_1_text),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        width: '240px',
        height: '270px',
        
    },
    page6_1_icon_S: {
        backgroundImage: String.format('url({0})', page6_1_icon_S),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top',
        width: '240px',
        height: '132px',
        
    },
    page6_1_icon_SS: {
        backgroundImage: String.format('url({0})', page6_1_icon_SS),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '240px',
        height: '132px',
        
    },
    text_box: {
        //position: 'absolute',
        left: '0%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',  
    },
    text_box_m: {
        //position: 'absolute',
        left: '0%',     
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        whiteSpace: 'nowrap', 
    },
    text_box2: {
        display: 'flex',
        alignItems: 'center',
        padding: '2.3438vh 2.1875vw 2.6563vh 1.7188vw ',
        borderRadius: '1.5625vh',
        boxShadow: '0.2vw 0.3vh 60px #20202022 ',
    },
    text_box2_m: {
        padding: '34px 27px 34px 36px',
        borderRadius: '20px',
        boxShadow: '0.2vw 0.3vh 60px #20202022 ',
    },
    red_text_bold: {
        fontFamily: 'Noto Sans Korean',
        Height: '56px',
        lineHeight: '36px',
        fontWeight: 700,
        color: '#FFF',
        letterSpacing: '-1.5px',
        whiteSpace: 'nowrap',
        position: 'relative',
        
        backgroundColor: '#FF6262',
        padding: '12px 23px 8px 26px',
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#333375',
        letterSpacing: '-2px',
        // whiteSpace: 'nowrap',
        position: 'relative',
        
        
    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#333375',
        letterSpacing: '-2px',
        // whiteSpace: 'nowrap',
        position: 'relative',
        
    },
    text_grey_medium: {
        fontSize: '1.1458vw',
        lineHeight: '126%',
        fontWeight: 600, //400과 500은 피그마와 달리 웹에서는 구분이 안되서 이부분 600 처리
        color: '#5F5F69',
        letterSpacing: '-1px',
        // whiteSpace: 'nowrap',
        position: 'relative',
        display: 'flex',
        
    },
    text_grey_regular: {
        fontSize: '1.1458vw',
        lineHeight: '126%',
        fontWeight: 400,
        color: '#5F5F69',
        letterSpacing: '-1px',
        // whiteSpace: 'nowrap',
        position: 'relative',    
    },
    phone: {
        boxShadow: '0.2vw 0.3vh 60px #20202022',
        borderRadius: '2vw',
        width: '15.625vw',

    },
})

const TheBulja9 = props => {
    const classes = TheBulja9Styles()
    const isss = useMediaQuery('(min-width:1281px)')

    return (
        <Box style={{height: '100%', width: '100vw', backgroundColor: '#FFF', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                <Grid container className={isss ? classes.center_box : classes.center_box_m}>
                    
                    <Grid item className={isss ? classes.text_box : classes.text_box_m}>
                        <img src={require('../../images/thebulja/page9_red_box.svg').default} style={isss ? {margin: '14.375vh 0 2.8906vh', width: '6.77vw'} 
                                                                                                  : {margin: '160px 0 37px', width: '156px'}} alt=''/>
                        <Grid style={isss ? {display: 'flex'} : {}}>
                            <Typography className={classes.text_light} 
                            style={isss ? {whiteSpace: 'pre'} : {fontSize: '46px', textAlign: 'center'}}>많은 지원자 중에서 </Typography>
                            <Typography className={classes.text_light} 
                            style={isss ? {} :{fontSize: '46px'}}>최적의 인재를 분석하여,</Typography>
                        </Grid>
                        <Grid style={isss ? {display: 'flex'} : {}}>
                            <Typography className={classes.text_bold} 
                            style={isss ? {whiteSpace: 'pre'} : {fontSize: '46px'}}>가장 똑똑한 채용 스케줄을 </Typography>
                            <Typography className={classes.text_bold} 
                            style={isss ? {} :{fontSize: '46px', textAlign: 'center'}}>알려드립니다.</Typography>
                        </Grid>
                        <Box style={isss ? {minHeight: '5.3125vh'} : {minHeight: '124px'}}/>
                    </Grid>
                    
                    <Grid item style={{display: 'flex', alignItems: 'center'}}>
                        <img src={require('../../images/thebulja/page9_img.svg').default}  className={classes.phone} style={isss ? {} : {width: '208px', borderRadius: '19.36px'}}/>
                        <img src={require('../../images/thebulja/page9_img2.svg').default} className={classes.phone} style={isss ? {margin: '0 1vw'} : {zIndex: 1, width: '225.5px', margin: '0 -11px', borderRadius: '21.25px'}}/>
                        <img src={require('../../images/thebulja/page9_img3.svg').default} className={classes.phone} style={isss ? {} : {width: '208px', borderRadius: '19.36px'}}/>
                    </Grid>
                </Grid>         
            </Grid> 
        </Box>
    )
}

export default TheBulja9
