import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "../icons/CloseIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { analyticsEvent, searchEncoding } from "../../lib/common";

const termsServiceBusinessStyles = () => ({
  body: {
    //position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1110,
    backgroundColor: "#fff",
  },
  closeButton: {
    margin: "-3px 20px 20px",
    color: "#202020",
  },
  title: {
    fontSize: 18,
    lineHeight: "24px",
    color: "#202020",
    fontWeight: 500,
    //position: 'absolute',
    marginTop: -6,
    paddingLeft: 17,
  },
  texttitle: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 17,
    color: "#202020",
  },
  textsub: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 32,
    color: "#202020",
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 17,
  },
  text2: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 12,
  },
  text22: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 24,
  },
  maintext: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 17,
    lineHeight: "17px",
    textAlign: "left",
    position: "absolute",
    top: "56px",
    width: "calc(100% - 32px)",
    color: "#747474",
  },
});

const TermsServiceBusiness = (props) => {
  const { classes } = props;
  const topHeight = 39 - 16;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    try {
      analyticsEvent("HOMEPAGE", "VIEW", String.format("TERMS_SERVICE_BUSINESS_{0}", searchEncoding(location.search)));
    } catch (e) {
      console.error(e);
    }
  }, []);
  const handleCloseDefault = () => navigate(-1);

  return (
    <Grid container direction="column" className={classes.body}>
      <Grid item style={{ marginTop: topHeight, display: "flex" }}>
        <CloseIcon className={classes.closeButton} onClick={handleCloseDefault}></CloseIcon>
        <Typography className={classes.title}>서비스 이용약관(기업회원)</Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item className={classes.maintext}>
            <Typography style={{ height: 0 }} />
            <Typography className={classes.texttitle}>기업회원 이용약관</Typography>
            <Typography className={classes.textsub}>제1조 (목적)</Typography>
            <Typography className={classes.text}>
              본 약관은 (주)웍스비(이하 “회사")가 운영하는 더벌자 등의 온라인 웹사이트 및 모바일 어플리케이션(이하
              “더벌자")을 통하여 제공되는 “서비스"를 이용함에 있어, 구인 및 채용, 용역을 제공받는 것을 목적으로 하는
              이용고객(“회원" 및 본 약관에 따른 이용계약을 체결하지 않은 기타 서비스 방문자를 포함)과 “회사" 간의 권리,
              의무 및책임사항, 이용조건 및 제반 절차, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </Typography>

            <Typography className={classes.textsub}>제2조 (용어의 정의)</Typography>
            <Typography className={classes.text}>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</Typography>
            <Typography className={classes.text}>
              1. “서비스”: 회사가 운영하는 “더벌자"를 통해, 회원으로 가입한 이용자들이 구인, 구직, 근무 관리, 교육, 또는
              기타 직업 및 고용 관련 목적으로 등록한 자료를 DB화하여 가공, 분류, 연산하여 제공되는 모든 정보 및 부대
              서비스를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “이용자”: 서비스에 접속하여 “더벌자"에서 제공되는 정보를 열람하는 모든 분을 말합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 회원: 본 약관에 동의하고 가입절차를 완료함으로써 이용계약을 체결하고 가입절차에서 요구되는 개인정보를
              제공하여 회원등록을 한 분을 말합니다. 회원은 카카오 등 외부서비스 (이하 “외부 계정 정보 공급자") 연동을
              통해 가입절차를 완료한 분과 아이디와 비밀번호 설정 등 회원가입절차를 직접 거쳐 가입절차를 완료한 분을 모두
              포함합니다. 회원은 구인자 회원과 구직자 회원으로 분류되며, 본 약관의 이하 본문에서는 별도의 명시가 없는 한
              “회원"은 구인자 회원을 지칭합니다.
            </Typography>
            <Typography className={classes.text2}>
              1) “구인자 회원”: “더벌자"의 [기업회원] 화면을 통하여 회원으로 가입한 회원을 말하며, “서비스"를 활용하여
              구인 또는 채용을 하거나 용역을 제공받는 것을 목적으로 합니다.
            </Typography>
            <Typography className={classes.text2}>
              2) “구직자 회원”: “더벌자"의 회원가입 화면에서 바로 가입절차를 완료한 회원을 말하며, “서비스"를 활용하여
              구직 또는 취직을 하거나 용역을 제공하는 것을 목적으로 합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “아이디” : “회원” 식별과 서비스 이용을 위하여 회원이 지정하고 “회사”가 부여하는 문자와 숫자, 특수부호의
              조합을 말합니다.
            </Typography>
            <Typography className={classes.text}>
              5. “비밀번호” : “회원”이 회원가입을 완료하여 “아이디"를 부여받은 자와 동일인임을 확인하고 “회원"의 권익과
              정보를 보호하기 위하여 “회원"이 선정한 문자와 숫자, 특수부호의 조합을 말합니다. 단, “회원"이 “외부 계정
              정보 공급자” 통하여 가입절차를 완료한 경우, 해당 “외부 계정 정보 공급자"를 통해 제공받거나 “회사"와 “외부
              계정 정보 공급자" 간 인증 절차를 통해 암호화하여 부여된 문자와 숫자, 특수부호의 조합을 말합니다.
            </Typography>
            <Typography className={classes.text}>
              6. “개인정보" : 생존한 개인에 관한 정보 중 해당 개인을 식별할 수 있는 고유 정보(성명, 생년월일 및
              주민등록번호 등) 또는 다른 정보와 용이하게 결합하여 특정인을 식별할 수 있는 정보를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              7. “사업장 정보" : “회원"이 구인 활동을 하기 위해 “서비스" 내에 등록한 본인이 직접 운영 중이고 본인의
              명의로 사업자등록을 한 사업장의 정보를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              8. “구인공고" : “회원"이 구인 활동을 위해 구직자의 구직지원을 모집하기 위한 목적으로 등록한 정보 및
              광고로, 채용 시 해당 구직자에게 부여할 근무 조건 및 처우 등의 내용을 포함합니다.
            </Typography>
            <Typography className={classes.text}>
              9. “매칭" : “서비스" 내에서 “회사"가 구직자 회원에게 구인자 회원을, 구인자 회원에게 구직자 회원을, 또는
              구인자 회원과 구직자 회원 상호 간 연결하는 행위 및 이에 수반하여 제공되는 정보를 말합니다. “회사"는 채용
              성사를 보증하거나, 채용 성사의 대가로 “회원"에게 유료 요금을 요구하지 않으며, 특정한 구직자를 소개하거나,
              고용 알선하지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              10. “채용관리" : “서비스" 내에서 구인자 회원이 구직자 회원에게 채용 제안을 하거나 입사 지원을 받고 이를
              심사, 채용 여부를 결정하거나 해당 구직자 회원과 필요사항을 협의하는 등 채용관련 절차 (“채용절차")의 진행
              정보를 열람, 관리할 수 있는 서비스를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              11. “이력서" : 구직자 회원이 근무업체명 및 근무기간 등 “서비스"에 등록한 경력 정보를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              12. “프로필" : 구직자 회원이 “서비스"에 등록한 모든 정보를 통칭합니다 (“구직조건", “이력서"를 포함하며,
              회원의 아이디 및 비밀번호, 전화번호 및 휴대전화번호, 거주지 주소는 포함되지 않습니다)
            </Typography>
            <Typography className={classes.text}>
              13. “직거래" : 구직자 회원과 구인자 회원간 또는 구직자, 구인자를 불문한 이용자 간 “서비스"에서 제공되는
              정보와 관련하여 “서비스"에서 제공하는 절차를 통하지 않고 거래하거나 이를 시도하는 모든 행위를 말합니다.
              “서비스"의 “채용관리" 또는 “구직관리” 기능을 통하지 않고 (단, 면접 당일 구직자 회원과 구인자 회원 간 채용
              면접을 진행하는 것은 예외입니다) 직거래를 시도하거나 “서비스"에 게시된 임금 등의 조건 외의 금품, 보상을
              요구, 또는 제공하거나, 관계 법령에 어긋나는 개인 정보를 요청하는 행위가 그 예입니다.
            </Typography>

            <Typography className={classes.textsub}>제3조 (약관의 명시와 개정)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알
              수 있도록 “서비스" 내에 게시하거나, 기타의 방법으로 이용자에게 공지해야 합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"가 약관을 개정하는 경우, 개정되는 약관 및 적용일자를 시행일자 7일 전부터 시행일자 전일까지
              공지하여야 합니다. 공지하는 방법은 “서비스" 내 공지사항 또는 이용약관 페이지, 또는 서비스 내 알림, 또는
              “회사"의 홈페이지(http://www.worksbe.com)를 통하는 것을 원칙으로 합니다.
            </Typography>
            <Typography className={classes.text}>
              4. 만약 개정되는 약관이 “회원"의 권리 및 의무에 중대한 영향을 주는 변경사항이 있을 경우에는 적용일자 30일
              전부터 공지 합니다
            </Typography>
            <Typography className={classes.text}>
              5. “회원"은 변경되는 약관에 대해 거부할 권리가 있습니다. 변경된 약관에 동의하지 않는 “회원"은 서비스를
              이용할 수 없으며, “서비스" 또는 고객센터를 통해 탈퇴를 요청할 수 있습니다. 이 때, “회사"는 “회원"의 탈퇴
              요청을 수락하여 “회원"과의 이용계약을 해지합니다.
            </Typography>
            <Typography className={classes.text}>
              6. 본조의 공지에도 불구하고, “회원"이 개정약관의 시행일자 전날까지 별도의 거부의사를 표시하지 않는 경우,
              “회원"이 변경된 약관을 승인한 것으로 봅니다.
            </Typography>

            <Typography className={classes.textsub}>제4조 (약관의 해석 및 약관 외 준칙)</Typography>
            <Typography className={classes.text}>
              1. 본 약관에서 규정하지 아니한 사항에 관해서는 관계법령 또는 일반 상관례, 서비스를 이용하면서 고지되는
              세부이용지침 등을 따릅니다.
            </Typography>
            <Typography className={classes.text}>
              2. 만약 “회원"이 “회사"와 개별계약을 체결하여 서비스를 이용할 경우, 당해 개별 계약이 우선합니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 필요한 경우 특정 서비스에 대해 이용안내 또는 개별 약관을 별도로 고지할 수 있으며, 그 내용이 본
              약관과 상충될 경우, 해당 개별약관 또는 이용안내의 조항이 우선하여 적용됩니다.
            </Typography>

            <Typography className={classes.textsub}>제5조 (이용계약의 성립)</Typography>
            <Typography className={classes.text}>
              1. “회사"가 제공하는 서비스를 이용하고자 하는 자는 회원가입을 신청하여, 회사가 정한 절차를 거쳐 가입해야
              합니다.
            </Typography>
            <Typography className={classes.text}>
              2. 제1항의 신청에 있어, 회원가입을 희망하는 자는 본 약관과 [개인정보처리방침]을 읽고 동의 또는 확인 여부를
              선택할 수 있으며, 동의 또는 확인을 선택한 경우 이에 동의한 것으로 간주합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 제1항의 신청에 있어, “회사"는 회원가입 절차를 진행함에 있어, “회사"가 정한 개인정보의 등록을 요청할 수
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. 제1항의 신청에 있어 “회사"는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수
              있으며, 이 인증을 위해 필요한 정보를 요청할 수 있으며, 이용계약을 희망하는 자는 해당 정보를 제공하여야
              합니다.
            </Typography>
            <Typography className={classes.text}>
              5. 제1항의 신청에 있어, “회사"는 기업인증을 위해 사업자정보를 확인할 수 있는 증명서(사업자등록증 등)을
              별도로 요구하거나, 가입 신청인에게 사업자번호 등 사업자정보를 요청한 후 이를 제3의 기관에게 인증 요청을 할
              수 있습니다. 만약 가입 신청인이 사업자명 및 사업자등록번호 등 사업자 정보를 허위로 등록하거나 휴,폐업된
              사업자 정보로 등록한 경우 “회원"은 본 약관 상 “회원"에게 인정되는 일체의 권리를 주장할 수 없으며, “회사"는
              그 “회원"에게 일체의 의무를 지지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              6. 회원가입을 희망하는 자가 회원가입절차 및 “회사"에서 요청하는 정보의 등록을 완료하고 제2항의 동의 및
              제3항의 절차를 완료한 후, 회사가 그 서비스 이용계약에 대한 신청이 완료된 것으로 봅니다. 단, “회사"는
              제5항의 인증절차를 통해, 그 진행 경과 및 결과에 따라 가입 신청인의 회원가입 신청을 심사하거나, 유보, 보류,
              거절할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              7. 만약 “외부 계정 정보 공급자"와의 연동을 통해 이용계약을 신청할 경우, 제2항의 동의 절차 및 해당 “외부
              계정 정보 공급자"의 계정 정보 접근 및 활용과 관련된 절차를 완료할 경우, 서비스 이용계약에 대한 신청이
              완료된 것으로 봅니다.
            </Typography>
            <Typography className={classes.text}>
              8. 서비스 이용계약은 본조 제 5항 및 제6항의 이용계약 신청 후, “회사"가 그 신청을 승낙한 때에 성립합니다.
            </Typography>
            <Typography className={classes.text}>
              9. “회사”는 “회원"에 대해 “회사"가 정한 기준에 따라 소정의 등급을 부여하고, 그 등급에 따라 서비스 이용에
              차등을 둘 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제6조 (이용신청의 승낙과 제한)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 이용계약 신청을 한 고객에 대하여, 본 조에서 정한 사유 등 특별한 사정이 없는 한 원칙적으로
              이용계약 신청 접수 순서에 따라 그 신청을 승낙합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 이용자가 아래 각 호 중 하나 이상에 해당하는 경우에는, 해당 이용자에 대하여 서비스 이용신청을
              승낙하지 아니하거나, 제한, 또는 유보할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>1) 만 15세 미만인 자</Typography>
            <Typography className={classes.text2}>2) 실명이 아니거나 타인의 명의를 이용하여 신청한 경우</Typography>
            <Typography className={classes.text2}>
              3) 타인의 사업장 정보를 도용하거나, 허위 또는 휴,폐업한 사업장 정보를 등록한 경우
            </Typography>
            <Typography className={classes.text2}>
              4) 대표자명, 또는 실제 회사명, 업종 등 사업장 정보 및 사업자 정보와 사업자등록번호가 일치하지 않는 경우
            </Typography>
            <Typography className={classes.text2}>
              5) “회사"가 사업자 및 사업장 정보에 대한 증빙 자료 제출을 요청했음에도 불구하고 이에 응하지 않을 경우
            </Typography>
            <Typography className={classes.text2}>
              6) 유흥업, 사행업종 또는 관계법령에 의거 온라인 구인 광고의 게재가 불가능한 업종 또는 사업자, 사업장일
              경우
            </Typography>
            <Typography className={classes.text2}>
              7) 근로기준법 등 고용 관련 법령에 의해 범칙금, 과태료 또는 처벌을 받은 이력이 확인되는 경우
            </Typography>
            <Typography className={classes.text2}>
              8) “회원"이 영위하는 업종 및 업태, 사업장 소재지 및 구인하고자 하는 직종에 대해 “회사”의 사정상 “서비스"
              제공이 불가하다고 판단하는 경우
            </Typography>
            <Typography className={classes.text2}>
              9) 기타 “회사"가 “서비스" 이용자의 건전한 구인, 구직 활동을 보호하기 위해 필요하다고 판단하는 경우
            </Typography>
            <Typography className={classes.text2}>
              10) 이용계약 신청서의 내용을 허위로 기재하거나 “회사"가 제시하는 내용을 기재하지 않는 경우
            </Typography>
            <Typography className={classes.text2}>
              11) 본 약관 외 “회사"가 “서비스" 제공을 위해 필요하다고 판단하는 고지 내용에 대해 “동의" 또는 “확인"을
              표시하지 않는 경우
            </Typography>
            <Typography className={classes.text2}>
              12) 이전에 “서비스" 이용 중 이용자 본인의 귀책사유로 인하여 “회사" 또는 타 “회원" 및 “구인회원"의 “서비스"
              이용에 지장을 초래한 이력이 있는 경우
            </Typography>
            <Typography className={classes.text2}>
              13) 제7조 제2항에 따라 회사가 이용계약 해지 조치를 한 이력이 있는 경우
            </Typography>
            <Typography className={classes.text2}>
              14) 이용자의 귀책사유로 인해 승인이 불가능하거나 기타 규정한 제반사항을 위반하여 신청하는 경우
            </Typography>
            <Typography className={classes.text2}>15) 기타 회사의 사정상 제한이 필요하다고 인정되는 경우</Typography>
            <Typography className={classes.text}>
              3. “회사"는 아래 각 호 중 어느 하나에 해당하는 경우에는, 서비스 이용 신청에 대하여 승낙제한 사유가 해소될
              때까지 신청의 승낙을 유보할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>1) “회사"의 설비가 여유가 없는 경우</Typography>
            <Typography className={classes.text2}>2) “회사"의 기술 또는 업무상 문제가 있거나 우려되는 경우</Typography>

            <Typography className={classes.textsub}>제7조 (이용계약의 종료 및 제한)</Typography>
            <Typography className={classes.text}>1. “회원"은 언제든지 이용계약을 해지할 수 있습니다.</Typography>
            <Typography className={classes.text2}>
              1) “회원"은 “서비스" 내에 제공되는 회원탈퇴 기능 또는 고객센터를 이용하여 이용계약 해지를 신청할 수
              있으며, “회사"는 그 즉시 이를 수락하고 관련법 등이 정하는 바에 따라 이를 처리합니다.
            </Typography>
            <Typography className={classes.text2}>
              2) 본 항에 따라 이용계약을 해지한 회원은 이후 다시 “서비스" 가입 신청을 할 수 있습니다. 단, “회사"는
              일정기간 동안 재가입을 제한할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “다음 각 호의 어느 하나에 해당하는 경우, ”회사"는 사전 동의 없이 “회원"의 이용계약을 해지하거나 서비스
              전부 또는 일부 이용 중지 (“회원"이 가입 시 등록한 정보를 제외한, “회원"이 서비스 이용 중 등록한 정보 또는
              “서비스" 이용 정보를 삭제할 수 있습니다) 조치를 취할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) “회원"에게 제6조에서 정한 이용신청의 승낙, 유보, 또는 제한의 사유가 있음이 확인된 경우
            </Typography>
            <Typography className={classes.text2}>
              2) “회원"이 제22조에서 명시한 의무사항을 위반한 사실이 있음이 확인된 경우
            </Typography>
            <Typography className={classes.text2}>
              3) 다른 구직자 회원 및 구인자 회원의 신고가 3회 이상 접수된 경우
            </Typography>
            <Typography className={classes.text2}>
              4) “회원"이 타인의 사업장 정보를 도용, 또는 허위로 등록하거나, 등록한 사업장 정보의 전부 또는 일부가
              실제와 다른 경우
            </Typography>
            <Typography className={classes.text2}>
              5) 실제 구인 의사가 없음에도 불구하고 3회 이상 반복적으로 “구인공고"를 등록하는 경우
            </Typography>
            <Typography className={classes.text2}>
              6) “회원"이 등록한 “구인공고"의 내용이 실제와 현저히 다를 때
            </Typography>
            <Typography className={classes.text2}>
              7) “회원"이 실제 구인 의사가 없음에도 불구하고 상당히 반복적으로 구직자 회원에게 채용제안을 반복하고 그
              구직자회원에 대해 면접 등 채용과정을 진행하지 않을 경우
            </Typography>
            <Typography className={classes.text2}>
              8) “회원"이 정당한 사유 없이, 자신의 “구인공고"를 구직 지원을 한 구직자 회원이 있음에도 불구하고
              반복적으로 삭제를 반복하는 경우
            </Typography>
            <Typography className={classes.text2}>
              9) “회원"이 채용완료 전까지 “서비스"를 통해 구직 지원을 한 구직자 회원에게 직거래를 유도, 시도하거나, 다른
              회원의 직거래 요청에 응하는 경우
            </Typography>
            <Typography className={classes.text2}>
              10) “회원"이 본인이 등록한 “구인공고"에 대해 구직지원을 한 구직자 회원에 대하여, 그 응답 또는 응대를
              성실히 수행하지 않았음이 입증되는 경우
            </Typography>
            <Typography className={classes.text2}>
              11) “회원"이 본인이 등록한 “구인공고"의 채용조건 및 “채용관리"를 통해 확정된 근무시간대 등 근무조건 및
              근무시간대와 다른 조건으로 근무할 것을 해당 구직자 회원에게 요구하거나 근로계약을 체결하였다는 신고가
              접수되고 “회사"가 그 신고 내용이 사실임을 확인한 경우 (단, 해당 구직자회원에게 “구인공고"의 조건을
              상회하는 수준의 임금 또는 복지 등 혜택을 부여하는 것은 예외입니다)
            </Typography>
            <Typography className={classes.text2}>
              12) “회원"이 정당한 사유 없이, “서비스"를 통해 채용한 구직자 회원의 채용을 취소하거나 “구인공고"에 기재된
              계약기간(근무기간) 종료일 이전에 해고를 하였고, 그 사실이 해당 구직자 회원 본인에 의해 “회사"에
              접수되었으며, 그 신고 내용이 사실임을 “회사"가 확인한 경우
            </Typography>
            <Typography className={classes.text2}>
              13) “회원"이 본인이 등록한 “구인공고"에 기재된 채용 시간대 중 특정 시간대에 대하여 채용을 완료하였음에도
              불구하고 그 채용 완료 사실을 “서비스"에 등록하지 않거나, 해당 시간대에 지원한 다른 구직자 회원에 대하여
              채용 중단 결정 통보를 반복적으로 지연하는 경우
            </Typography>
            <Typography className={classes.text2}>
              14) 동일 회원 아이디로 사실상의 동일 내용의 “사업장" 정보를 중복등록하거나, “구인공고" 등록일로부터 마감일
              또는 14일 중 후도래하는 날이 지나지 않은 시점에 사실상의 동일 내용의 “구인공고"를 중복 등록하는 경우
            </Typography>
            <Typography className={classes.text2}>
              15) “구인공고”에 구인 시간대 및 구인 직종, 임금, 무급 휴게시간 조건을 등록하지 않은 경우
            </Typography>
            <Typography className={classes.text2}>
              16) “회원"이 “구인공고"에 등록한 임금이 사실상 법정 최저임금에 미달한 경우
            </Typography>
            <Typography className={classes.text2}>
              17) 아웃소싱, 인력도급, 파견업, 채용대행을 목적으로 하는 구인임에도 불구하고 자사 “구인공고"인 것처럼 다른
              이용자의 혼란을 야기하는 “구인공고" 및 “사업장" 정보를 등록하는 경우
            </Typography>
            <Typography className={classes.text2}>
              18) 불건전한 유흥업소 및 관계법령에서 온라인 채용공고 및 온오프라인 구인 모집을 금지하는 “구인공고"를
              등록한 경우
            </Typography>
            <Typography className={classes.text2}>
              19) 구인을 가장한 다단계, 유학알선, 물품판매, 자금 모급, 회원모집, 카드회원 모집, 추천인 모집, 쇼핑몰
              분양, 직업소개, 부업알선 등의 내용의 “구인공고"를 올렸거나, 등록한 “구인공고"의 실제 목적이 이러하다는
              신고가 접수되고 그 신고 내용이 사실임을 “회사"가 확인하는 경우
            </Typography>
            <Typography className={classes.text2}>
              20) 프랜차이즈 모집, 점포개설, 창업, 사이트 홍보 등 직원 채용이 아닌 “구인공고”를 등록한 경우
            </Typography>
            <Typography className={classes.text2}>
              21) 취업수수료 및 금품, 별도의 대가 등 취업을 위해 구직자 회원의 비용이 요구되는 “구인 공고"를 등록한 경우
            </Typography>
            <Typography className={classes.text2}>
              22) 임금체불, 부당해고, 직장 내 따돌림 및 괴롭힘, 근로기준법 미준수 등으로 근로감독원에 진정서가
              접수되거나, 관계 당국에 고소, 고발되어 있거나, 관계 당국으로부터 처벌 또는 처분을 받은 경우
            </Typography>
            <Typography className={classes.text2}>
              23) 구직자 회원의 피해사례(채용과정 중 또는 채용 후 근무 중)가 접수되고 그 내용이 현저히 심각하다고
              “회사"가 판단하는 경우
            </Typography>
            <Typography className={classes.text2}>24) 취업수수료 등</Typography>
            <Typography className={classes.text2}>
              25) “회원"이 공공질서 및 미풍양속에 위배되는 행위를 하는 경우
            </Typography>
            <Typography className={classes.text2}>
              26) “회원"이 “회사"나 타인의 권리나 명예, 저작권 및 기타 정당한 이익을 침해하는 행위를 하였다고 판단되는
              경우
            </Typography>
            <Typography className={classes.text2}>
              27) “회원"이 “회사"가 “서비스"를 제공함에 있어 원활한 진행을 방해하는 행위를 하거나 시도하였다고 판단되는
              경우
            </Typography>
            <Typography className={classes.text2}>
              28) “회원"이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때
            </Typography>
            <Typography className={classes.text2}>
              29) “회원"이 무단으로 “회사"의 프로그램을 변경하거나 해킹하는 등의 행위를 하거나 시도하였다고 판단되는
              경우
            </Typography>
            <Typography className={classes.text2}>
              30) “회원"이 “회사”, 운영사, 타 구인자 회원 또는 구직자 회원을 사칭하는 경우
            </Typography>
            <Typography className={classes.text2}>
              31) “회원"이 “서비스"에 게시되는 데이터를 정당한 권한 없이 스스로 또는 제3자를 통해 유포하거나, 상업적으로
              이용하는 경우
            </Typography>
            <Typography className={classes.text2}>
              32) “회원"이 구직의사 없이 “구직회원"에 대하여 채용 지원 등의 행위를 반복하거나, 채용지원 후 반복적으로
              해당 구인자회원의 요청에 무응답 또는 채용 과정 상의 약속을 불이행하는 경우
            </Typography>
            <Typography className={classes.text2}>
              33) 본 약관과 별도로 체결되는 약관 및 동의 내용에 대하여 전부 또는 일부에 승낙거부 사유가 있음이 확인되는
              경우
            </Typography>
            <Typography className={classes.text2}>
              34) 회원에게 파산, 행위능력의 제한, 사망, 실종 등 정상적 서비스 이용이 불가능하거나 곤란한 사유가 발생하는
              경우
            </Typography>
            <Typography className={classes.text2}>
              35) 규정한 유료서비스에 대하여 이용신청을 한 후 이용 요금을 납부하지 않는 경우
            </Typography>
            <Typography className={classes.text2}>
              36) “회원"이 정당한 사유 없이 유료서비스 이용 대금의 결제 및 취소를 반복하는 경우
            </Typography>
            <Typography className={classes.text2}>37) 기타 “회원”이 본 약관에 위배되는 행위를 하는 경우</Typography>
            <Typography className={classes.text2}>38) 그 외 위 각 호에 준하는 사유에 해당하는 경우</Typography>
            <Typography className={classes.text}>
              3. “회사"는 회원가입이 해지된 경우에는 [개인정보처리방침] 중 [개인정보의 보유 및 이용기간 규정]을
              따릅니다. 이 규정에 해당하지 않는 회원의 모든 데이터는 해지 즉시 삭제함을 원칙으로 합니다.
            </Typography>

            <Typography className={classes.textsub}>제8조 (회원정보의 변경)</Typography>
            <Typography className={classes.text}>
              1. “회원"은 언제든지 “서비스" 내에서 개인정보를 열람할 수 있고, 수정을 요청할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. 일부 정보 항목을 제외하고는, “회원"은 필요한 경우 해당 정보 항목을 “서비스" 내에서 직접 변경할 수
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회원"은 언제든지 본인이 운영하는 “사업장"의 정보를 “서비스"에 등록하거나 이미 등록된 정보를 변경,
              삭제할 수 있습니다. “회사"는 “회원"이 등록, 변경하는 “사업장" 정보에 대하여 즉시 “서비스"에 반영합니다.
              단, 이때 “회사"가 필요하다고 판단하는 경우, 해당 “사업장" 정보의 사실 여부, 휴, 폐업 여부 및 실제 “회원"
              본인이 운영하는 사업장인지 등을 검토하거나 제3의 전문기관에 그 검토를 의뢰, 또는 “회원"에게 증빙을 위한
              추가 자료 제출을 요청할 수 있으며 “서비스"에 반영하는 것을 유보, 반려할 수 있으며, 해당 “사업장" 정보의
              진위 여부 및 신뢰성이 증명되지 않을 경우 사후에 “회원"의 동의 없이 해당 “사업장" 정보를 삭제할 수
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. 만약 “회원"이 실제 사업장의 대표자가 아닌 직원 등 타인의 명의로 “서비스"에 가입, “사업장" 정보를
              등록하였을 경우, 회원 정보 및 “사업장” 정보에 대한 관리의 책임은 가입 명의자와 “회원" 본인에게 있습니다.
            </Typography>
            <Typography className={classes.text}>
              5. 만약 “회원"이 등록한 사업장 및 회원정보에 대하여, “회원"이 해당 사업장의 실제 대표자가 아니고, 그
              사업장의 실제 대표자 또는 소유자가 “회사"에게 이를 신고하여 그 사실이 입증될 경우, “회사"는 “회원"에게
              통지 없이 “회원"의 이용계약을 해지하거나, 해당 “사업장"의 정보를 삭제, 또는 그 “사업장" 정보 및 그
              “사업장"을 통해 “서비스"에 등록된 “구인 공고" 및 “채용관리" 정보 등을 해당 실제 대표자 또는 소유자에게
              이관 조치할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              6. 단, “서비스” 내에서 직접 수정이 불가한 개인정보 항목에 대해서는 고객센터를 통해 수정을 요청할 수
              있습니다. 이 경우, “회사"는 정보의 수정을 위해 필요한 사항을 전문기관을 통해 요청하거나 “회원”에게 추가
              정보 또는 자료의 제출을 요청할 수 있으며, “회원"이 수정을 요청한 사항이 사실과 일치하는 것이 확인될 경우
              수정이 가능합니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회원"은 이용계약 신청 시 기재한 사항이 변경되었을 경우, “서비스" 내 수정기능 또는 고객센터를 통해 즉시
              “회사"에 그 “변경사항"을 알려야 합니다. 만약 이를 이행하지 않을 경우, 발생하는 손해에 대한 책임은 그
              “회원"에게 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제9조 (서비스의 내용)</Typography>
            <Typography className={classes.text}>
              1. 회사는 “회원"에게 제2조 제1항의 서비스를 제공할 수 있으며, 그 내용은 다음 각 호와 같습니다.
            </Typography>
            <Typography className={classes.text2}>1) “사업장" 및 “구인공고" 등록 및 게재 서비스</Typography>
            <Typography className={classes.text2}>2) 구직자 회원에게 구인 정보 추천, 게재 서비스</Typography>
            <Typography className={classes.text2}>3) 인재 정보 제공 서비스</Typography>
            <Typography className={classes.text2}>4) 제11조에 따른 매칭 서비스</Typography>
            <Typography className={classes.text2}>
              5) 온라인 구인 및 채제안, 채용관련 절차 및 채용관리 관련 서비스
            </Typography>
            <Typography className={classes.text2}>6) 구인 및 교육과 관련된 제반 서비스</Typography>
            <Typography className={classes.text2}>7) 이용자 간의 교류와 소통에 관련한 서비스</Typography>
            <Typography className={classes.text2}>8) 자료 거래에 관한 서비스</Typography>
            <Typography className={classes.text2}>
              9) 기타 회사가 추가, 개발, 편집, 재구성하거나 제휴계약 들을 통해 제공하는 일체의 서비스
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 필요한 경우 서비스의 내용을 추가 또는 변경, 중단할 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제10조 (사업장 및 구인공고 정보)</Typography>
            <Typography className={classes.text}>
              1. “회원"은 “서비스" 내에서 “사업장" 및 “구인공고" 정보를 작성, 수정, 삭제할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. 만약 “회원"이 “사업장" 또는 “구인공고" 정보의 일부를 등록하지 않은 경우, “회사"는 “회사"가 정한 기준에
              따라 그 정보를 임의의 값으로 지정할 수 있습니다. 단, “회원"은 언제나 해당 정보를 변경할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회원"은 “구인공고" 정보를 작성, 수정 시, [근로기준법], [직업안정법], [남녀고용평등과 일▪가정 양립
              지원에 관한 법률], [고용상 연령차별금지 및 고령자 고용촉진에 관한 법률] 등 관계법령 및 제22조의 의무를
              준수해야 합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회원"은 “구인공고” 정보 작성 시, 특정 연령, 또는 성별, 계층, 인종 등을 차별하거나 배제해서는
              아니됩니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 “회원”가 작성, 수정한 “구인공고"가 본조의 내용 또는 관계 법령을 위배하거나, 다른 이용자의
              타당한 신고가 접수될 경우, 또는 회사의 “구인공고" 정보 노출 정책에 따라, “구인공고"의 내용을 수정하거나
              삭제, 게재 중단할 수 있습니다.
            </Typography>

            <Typography className={classes.text}>
              6. 만약 “회원"이 제12조의 채용관리에 따라, “서비스" 내에서 구인 및 채용이 완료되거나 신규 구직 지원 건이
              발생하거나, 채용관련 절차에 변경사항이 있을 경우, 해당 추가, 변경, 완료 건에 따른 내용은 반영되어 관련된
              “구직공고"의 내용이 자동 변경되거나 추가될 수 있습니다. 단, “회원"은 언제나 해당 정보를 변경할 수
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회원"의 “사업장" 정보 및 “구인공고"는 해당 “구인공고" 건이 현재 구인 진행 중이거나 마감이 되지 않은
              경우에 한하여 “서비스" 내에서 게재됩니다.
            </Typography>
            <Typography className={classes.text}>
              8. “회사"는 공지사항 등을 통해 “서비스" 내에서 고지한 기간(별도의 공지가 없는 경우 14일로 합니다) 범위
              내에서 “회원"이 등록한 “구인공고”가 종료, 삭제, 마감되지 않는 한 “구인공고"를 “서비스" 내에 게재합니다.
              만약 그 기한을 경과한 경우, “회사"는 임의로 “구인공고"의 게재를 중단하고 해당 “구인공고"에 대하여 제13조에
              따라 채용 진행 중인 구직자 회원의 채용 진행을 종료 처리할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              9. “회사"는 “회사"가 정한 기준에 의하여 구인자 회원들의 “구인공고"의 게재 위치 및 노출 순서, 빈도를 정할
              수 있습니다. 단, “서비스" 내 지도 화면에 표시하는 경우, “회원"의 “구인공고"는 “회원"이 등록한 “사업장"
              정보의 주소지에 해당하는 좌표에 표시하는 것을 원칙으로 합니다.
            </Typography>
            <Typography className={classes.text}>
              10. “회원"이 등록한 “구인공고"에 대하여, 그 “구인공고" 상의 구인시간대 및 채용인원 중 특정 시간대에
              “서비스" 내에서 채용이 완료되었음이 확인되는 경우, “회사"는 이를 즉시 “서비스"에 게재되는 “회원"의
              “구인공고"에 반영하여 노출합니다. 이는 “회원"이 실제 채용이 필요한 조건에 대하여 원활한 구인 활동이
              가능하도록 지원하고, 구직회원들의 구직 활동에서의 편의성을 제고하기 위함입니다.
            </Typography>
            <Typography className={classes.text}>
              11. “회원"의 “사업장” 및 “구인공고” 정보, “채용관리" 상의 채용 진행 현황 및 완료 여부 등은 제 11조에 따른
              매칭 서비스에서, “회원"에게 적합하다고 판단되는 구직자 회원을 매칭하거나, “회원"과 적합하다고 판단되는
              구직자 회원에게 “회원"의 구인 정보를 추천하는데 사용됩니다.
            </Typography>
            <Typography className={classes.text}>
              12. “회원”의 “사업장” 및 “구인공고" 정보, “채용관리" 상의 채용진행 현황 및 지원자, 채용완료 현황 관련
              데이터는 “회사"가 안정적인 서비스를 제공하기 위한 테스트 및 모니터링 용도, 또는 통계 분석, 서비스 개발
              등을 위하여 사용될 수 있으며, “서비스" 운영자가 이 정보를 열람할 수 있습니다. 단, “비밀번호” 등
              회원개인정보의 도용 및 침해 우려가 있는 정보는 그러하지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              13. “회사"는 [개인정보처리방침] 및 [개인정보 제3자 제공 동의] 또는 본 약관에서 별도로 명시한 경우 또는
              관련법령에 의거한 경우를 제외하고는 제3자에게 “회원"의 연락처 정보 및 생년월일 정보 등 개인 고유 정보를
              구인자 회원을 포함한 제3자에게 제공하지 않습니다. 단, 구인 구직과정에서 필요한 경우, 생년월일 정보는
              연령으로 재가공하여 제공합니다.
            </Typography>
            <Typography className={classes.text}>
              14. “회원"은 “구인공고" 및 “사업장"정보, “채용관리" 상의 채용진행 상태 및 완료 여부를 실제 사실에 근거하여
              등록하여야 합니다. 이 정보들이 허위 또는 과장되어 작성된 것이 확인될 경우 “회사"는 해당 “회원"의 “서비스"
              이용을 제한하거나 이용계약을 해지할 수 있습니다. “회사"는 “회원"이 등록한 “사업장" 및 “구인공고",
              “채용관리" 상에 등록되는 채용진행 상태에 대한 진위 검증의 의무가 없으며, 이들 정보가 허위 또는 과장되어
              작성되었을 경우 발생하는 손해에 대한 책임은 “회원"에게 있습니다. 또한, “회사"는 “회원"이 등록한 “구인공고"
              및 “사업장" 정보의 사실 여부를 확인하기 위해, “회원"이 등록한 “구인공고" 및 “사업장” 정보의 게재를
              유보하거나 반려할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              15. “회원"의 정당한 사유 없는 채용 진행 번복 및 취소, 등록한 “구인공고"의 내용과 다른 조건으로의 채용,
              직거래 유도 및 시도, 면접 불참, 그 외 채용제안 이력, 다른 회원의 신고 및 평가, 제12조 채용관리의
              채용절차의 이용 이력 등은 자동으로 기록되고, 이 중 전부 또는 일부는 “회원"의 개인 등급에 자동 반영됩니다.
              이들 정보는 “회원"의 구인과정에서 구직자 회원에게 노출될 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제11조 (매칭)</Typography>
            <Typography className={classes.text}>
              1. “서비스"는 “회원"이 등록한 “사업장” 및 “구인공고" 정보에 근거하거나, 각 “구인공고"별 “채용관리" 이용
              이력 및 채용 완료 여부, “서비스" 내에서 이 “구인공고"에 지원한 구직자 회원 현황에 근거하여, 적합하다고
              판단되는 구직자 회원을 “회원"에게 추천할 수 있습니다 (이하 “매칭")
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 “회원"에게 “회원"의 “구인공고"에 구직 지원하지 않은 구직자 회원 중 제1항에 따라 적합하다고
              판단되는 인재와 그 근무시간대를 매칭할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 “회원"에게 구직 지원하였거나, 또는 제2항에 의해 추천되는 인재에 대하여 “회원"이 채용제안을 한
              구직자 회원 중 “회사"가 정한 기준에 의하여 가장 적합한 인재 조합 및 그 근무스케줄을 매칭할 수
              있습니다(“똑똑하게 채용하기") 이 과정에서 “회사"는 임의의 기준에 따라 “회원"의 원활한 구인활동에
              필요하다고 판단될 경우, 전부 또는 일부 구직자 회원의 근무지원시간대를 “회원"의 사전 동의 없이 변경할 수
              있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 구직자 회원에게 “회원"의 “구인공고" 정보를 추천함으로써, “회원"과 해당 구직자 회원을 매칭 할
              수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 “회원"의 인재 선발을 지원하기 위해, “구인공고"에 구직 지원한 구직자 회원의 적합도를 “회사"가
              정한 기준에 따라 산출하여(“매칭률"), 그 산출된 값을 제시할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회사"는 구직자 회원의 원활한 구직활동을 지원하기 위해, “서비스" 내에 게시되는 “구인공고"에 “회사"가
              정한 기준에 따라 산출된 해당 “구인공고"와 구직자 회원 간의 적합도(“매칭률")을 제시할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              7. 제4항과 제5항의 “매칭률"은 “회사"가 임의로 정한 기준에 따라 정한 참고 데이터로서, 그 값의 실제 정확도에
              대해 “회사"는 책임을 지지 않으며, 그 데이터의 참고 여부 및 그 결과에 대한 책임은 “회원"에게 있습니다.
            </Typography>
            <Typography className={classes.text}>
              8. “회사"는 “회원"에게 가능한 한 적합한 구직자 회원과 그 근무스케줄이 매칭될 수 있도록 최선을 다합니다.
            </Typography>
            <Typography className={classes.text}>
              9. 일부 “매칭" 서비스는 “회원"이 “사업장" 정보 및 “구인공고" 정보의 전부 또는 일부를 등록하지 않거나,
              등록한 정보가 “회사"가 정한 기준 범위 밖일 경우 이용이 제한될 수 있습니다. 단, “회원"이 해당 “사업장" 및
              “구인공고" 정보를 등록 또는 변경할 경우 “회원"은 그 “매칭" 서비스를 이용할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              10. “회원"이 이미 채용 중이거나, 채용제안을 하였거나, “회원"이 등록한 “구인공고"에 구직 지원 중, 또는
              과거에 제12조에 따른 채용절차 진행 중 채용 중단 이력이 있는 구직자 회원에 대해서는 “매칭"이 되지 않거나
              “서비스” 내에서 채용 제안이 제한될 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              11. “회원"은 “더벌자" “서비스"를 이용하여 구직자 회원과 채용 절차를 진행하거나 채용 제안을 해야 하며, 다른
              수단을 통하여 매칭 또는 구직 지원을 진행 하거나 유도할 수 없습니다.
            </Typography>
            <Typography className={classes.text}>
              12. “회사"는 구인자 회원과 구직자 회원을 보호하고, 원활한 구인 구직 서비스를 제공하기 위하여 직거래 유도를
              금지하고 있습니다. “회사"가 정한 절차 이외의 수단을 통한 매칭 및 거래 시, 이용계약 해지 또는 “서비스" 이용
              제한 등 불이익이 있을 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제12조 (채용관리)</Typography>
            <Typography className={classes.text}>
              1. “채용관리"란 “서비스" 내에서 “회원"이 “매칭”된 구직자 회원에게 채용 제안을 하거나, 해당 구직자
              회원으로부터 구직 지원을 받은 단계부터 해당 구인 건이 중단되거나, 또는 합격하여 출근을
              완료(“채용완료")하는 단계까지의 제반 채용 단계를 관리하고 “서비스"에서 제공하는 절차에 따라 구직자 회원과
              소통할 수 있는 서비스를 말합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 “채용관리"에 등록되는 각 구직자 회원별 채용단계 정보를 자동으로 기록하고, 이를 “회원” 및 해당
              구직자회원에게 통지하거나, 또는 다른 구직자 회원에게 해당 구인자 회원을 “매칭"할 때 사용할 수 있습니다.
              단, 매칭된 구직자 회원의 정보는 식별할 수 있는 정보는 다른 구직자 회원에게 이 구인자 회원을 “매칭" 시에
              제공하지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 “회원"이 “채용관리" 서비스를 통하여, 구인 및 채용절차를 원활하고 편리하게 진행할 수 있도록
              최선을 다합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 구직자 회원의 생년월일 정보 및 주소지, 연락처 정보를 “회원"에게 제공하지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              5. 제12조 제3항의 “똑똑하게 채용하기"에서, “회사"는 “회사"가 정한 기준에 의하여 “회원"의 구인 활동에
              적합하다고 판단할 경우, “회원"의 사전 동의 없이 해당 구직자 회원의 근무지원 시간대를 변경하고, 이에 대한
              협의 및 수락의사 확인 메세지를 “채용관리"를 통해 자동으로 해당 구직자 회원에게 발송할 수 있습니다. 이
              경우, 만약 해당 구직자 회원이 근무시간대 변경 요청에 “수락"할 경우 근무지원시간대는 자동으로 변경되어
              “회원"에게 제시됩니다.
            </Typography>
            <Typography className={classes.text}>
              6. 구직 지원 또는 채용제안부터 “채용완료"에 이르기까지 모든 채용절차를 진행함에 있어, “회원"은
              “서비스"에서 제공되는 절차 이외의 방법으로 구직자 회원과 직거래를 하거나, 시도, 유도하거나, 상대방의
              직거래 요청에 응해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회원"은 “채용관리"를 통해 특정 구직자 회원의 채용이 완료되었을 경우, “채용관리"를 통해 해당 구직자
              회원의 채용이 완료되었음을 “서비스"를 통해 등록합니다.
            </Typography>
            <Typography className={classes.text}>
              8. “회사"는 이용자들의 원활한 구인, 구직을 위해 다음 각 호의 경우에 자동으로 “회원"과 특정 구직자 회원
              간의 채용 진행 단계를 종료 처리할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 해당 구직자 회원이 지원한 근무시간대(또는 제5항에 따른 변경된 근무시간대)와 겹치는 시간대에 “회원"의
              “구인공고"에 지원한 다른 구직자 회원의 채용이 완료되었을 경우
            </Typography>
            <Typography className={classes.text2}>
              2) 해당 구직자 회원이 지원한 근무시간대(또는 제5항에 따른 변경된 근무시간대)와 겹치는 시간대에 그 구직자
              회원이 다른 구직자 회원에게 채용이 완료되었을 경우
            </Typography>
            <Typography className={classes.text2}>
              3) 해당 구직지원 또는 채용제안 건에 대하여 “회원" 또는 해당 구직자 회원이 “채용관리"에서 상대방의 요청
              또는 채용진행에 대하여 응답하지 않은지 3일 이상 또는 “회사"가 공지사항을 통해 별도로 공지한 기한을 경과한
              경우
            </Typography>
            <Typography className={classes.text2}>
              4) “회원"이 해당 “구인공고”의 게재를 중단하였거나 삭제, 마감한 경우
            </Typography>
            <Typography className={classes.text2}>
              5) 해당 구직자 회원 또는 “회원"이 제7조에 따라 서비스 이용이 해지되었거나 제한된 경우
            </Typography>

            <Typography className={classes.textsub}>제13조 (제휴를 통한 서비스)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 제휴 관계를 체결한 여타 인터넷 웹사이트 및 모바일 어플리케이션, 오프라인 매체를 통해
              “서비스"에 등록한 “회원"의 “사업장" 정보 및 “구인공고" 정보가 열람되거나 또는 해당 제휴 서비스에 등록된
              구직자와 “회원"이 매칭될 수 있도록 서비스를 제공할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. 단, 이 경우, “회원"이 그 제공을 동의한 제휴 서비스에 한하여 제공되며, “회원"의 생년월일 및 연락처 정보
              등은 제공되지 않습니다. (생년월일 정보는 연령으로 재가공하여 제공합니다)
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 제휴를 통해 타 서비스에 등록될 수 있음을 고지해야 하며, 해당 제휴 서비스 목록을 “서비스"
              내에서 상시 열람할 수 있도록 해야 합니다. 단, 그 제휴사가 xml 또는 api 형태로 정보를 제공하거나 제공받는
              경우에는 본 약관 외 별도의 제휴 리스트에서 열람할 수 있도록 제공합니다.
            </Typography>
            <Typography className={classes.text}>
              4. 제휴 서비스 목록에 변경사항이 있을 경우 “서비스" 공지사항을 통해 고지 후 진행하며, “서비스" 공지사항에
              별도의 제휴 목록이 없는 한 본 조에 따른 제휴를 통한 타 서비스 또는 매체는 없습니다.
            </Typography>

            <Typography className={classes.textsub}>제14조 (서비스의 요금)</Typography>
            <Typography className={classes.text}>
              1. “회원"의 “사업장" 및 “구인공고"의 등록 및 게재는 “회사"가 별도로 그 사용기간 및 횟수를 공지하지 않는 한
              무료입니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 동일한 유료서비스에 대하여 결제경로 및 결제수단에 따라 이용 요금 및 할인혜택 등을 차등 적용할
              수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. 그 외 채용제안 및 제11조 제2항, 제3항의 매칭 및 그 외 부가서비스, 제1항 “사업장" 및 “구인공고" 등록 및
              게재 무료 서비스의 횟수 및 기간을 초과한 이용에 대하여 “회사"는 유료서비스로 제공할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 유료서비스의 요금 및 상세내용을 “회사" 홈페이지 또는 “서비스" 어플리케이션의 해당 유료서비스
              페이지 또는 공지사항을 통해 공지하여야 합니다.
            </Typography>
            <Typography className={classes.text}>
              5. 법인 사업자 또는 기타 사용 요금의 협의가 필요한 회원의 경우에는 “회사"와의 개별 합의를 통해 별도의
              계약서를 작성할 수 있으며, 이에 명시된 이용 요금을 우선하여 적용합니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회사"는 유료서비스의 이용 요금 및 종류, 기간을 변경할 수 있습니다. 단, 변경된 요금을 소급하여 적용하지
              않습니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회원"은 유료서비스의 이용 요금을 지급함에 있어, “회사"가 정한 결제 방법 중 하나를 선택할 수 있습니다.
              “회원"은 결제의 이행을 위하여 필요한 정보를 정확하게 입력 하여야 하며, 만약 이 정보를 허위 또는 부정확하게
              입력하였거나 타인의 결제수단을 도용하였을 경우, “회사"는 “회원"의 동의 없이 그 결제를 취소, 또는
              “회원"에게 유료서비스 이용을 제한할 수 있으며, 이로 인하여 발생하는 “회원" 및 타인의 손해에 대한 책임은
              “회원"에게 있습니다. 단, “회사"의 고의 또는 중과실에 의한 경우는 “회사"에게도 책임이 있는 것으로 봅니다.
            </Typography>

            <Typography className={classes.textsub}>제15조 (서비스 요금 환불 및 오류 조정)</Typography>
            <Typography className={classes.text}>
              1. “회사”는 다음 각 호에 해당하는 경우 이용 요금을 환불합니다. 단, 각 당사자의 귀책사유 또는 “회원"의 해당
              유료서비스 이용 이력에 따라 환불 조건이 달라질 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>1) 유료서비스 이용이 개시되지 않은 경우</Typography>
            <Typography className={classes.text2}>
              2) 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우
            </Typography>
            <Typography className={classes.text2}>
              3) 유료서비스 신청 후 “회원"의 사정에 의해 서비스가 취소될 경우
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 “회원"이 제7조에 따라 이용계약을 해지하거나, 제7조 제2항의 서비스 이용 제한에 해당하건, 또는
              “회원"이 “서비스" 이용규정을 위반한 경우 이용료를 환불하지 않으며, 서비스 이용 제한 등 추가적인 제재조치를
              할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. 이용료를 환불 받고자 하는 “회원"은 전화 및 메일로 환불요청을 해야 합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 “회원"의 환불 요청을 받는 즉시 사실을 확인하고, 환불 조건에 따라 유료이용 계약 당시 고지된
              상품의 단가 (단, 기간의 정함이 있는 유료서비스일 경우 고지된 상품의 단가 중 최소 기간에 해당하는 단가,
              횟수의 정함이 있는 유료서비스일 경우 고지된 상품의 단가 중 최소 횟수에 해당하는 단가를 기준으로 하며 할인
              등 “회사"의 프로모션 혜택은 단가 산정 시 제외합니다)를 기준으로 환불 요청 시까지 “회원"이 해당
              유료서비스를 이용한 기간 또는 횟수에 해당하는 이용료를 차감한 잔액을 환불 요청이로부터 30일 이내에
              환불합니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 이용요금과 관련하여 오류가 있는 경우에는 “회원"의 요청, 또는 “회사"의 사전 통지에 의하여
              다음의 조치를 취합니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 과다납입한 요금에 대하여는 그 금액을 반환합니다. 그 반환 시점은 제4항의 기한을 따릅니다. 단, “회원"이
              동의할 경우 다음 청구 요금에서 해당 금액만큼을 감하여 청구합니다.
            </Typography>
            <Typography className={classes.text2}>
              2) 요금을 반환받아야 할 “회원"이 요금 체납이 있는 경우에는 반환해야할 요그에서 이를 우선 공제하고 그
              잔액을 반환합니다.
            </Typography>
            <Typography className={classes.text2}>
              3) “회사"는 과소 청구액에 대해서는 익월에 합산 청구합니다.
            </Typography>

            <Typography className={classes.textsub}>제16조 (서비스 이용시간)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공합니다. 단, “회사"는 서비스의 종류나
              성질에 따라 “서비스" 중 일부에 대하여 별도로 이용시간을 정할 수 있습니다. 이 경우, “회사"는 그 이용시간을
              사전에 “서비스” 내에서 공지하거나 “회원”에게 통지합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애 해결을 위한 보수작업 시간, 정기 PM 작업,
              시스템 교체작업, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수 있습니다. 만약 이 작업이 계획된
              작업일 경우, “회사"는 공지사항을 통해 서비스 중단 시간과 그 작업 내용을 알려야 합니다. 다만, 이 작업이
              “회사"가 사전에 통지할 수 없는 부득이한 사유에 의한 경우 사후 통지로 갈음할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. 본조 제2항의 경우, “회사"는 기간의 정함이 있는 유료서비스 이용자들에게는 그 이용기간을 연장하는 등의
              방법으로 보상할 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제17조 (서비스 제공의 변경 및 중지)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 “서비스"의 향상을 위해, 또는 이용자의 이익을 위해 필요하다고 판단하는 경우 기존 서비스를
              별도의 통지 없이 변경할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 다음 각 호에 해당하는 경우 “서비스"를 제한하거나 중지할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 원활한 서비스 제공이 곤란하거나 그 외 “회사"의 경영상 판단에 의한 경우
            </Typography>
            <Typography className={classes.text2}>2) 설비 보수 등 “회사"의 필요에 의한 경우</Typography>
            <Typography className={classes.text2}>
              3) 기간통신사업자가 전기통신서비스 제공을 중지하거나 기타 불가항력적인 사유에 의해 서비스 제공이
              객관적으로 불가능한 경우
            </Typography>
            <Typography className={classes.text2}>4) 기타 “회사"가 필요하다고 판단하는 경우</Typography>
            <Typography className={classes.text}>
              3. 제2항에 따라 “서비스"를 중지 또는 제한하는 경우, “회사"는 이를 시행 전에 공지합니다. 단, “회사"가
              통제할 수 없는 사유로 인해 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
            </Typography>
            <Typography className={classes.text}>
              4. 제2항의 경우, “회사"는 기간의 정함이 있는 유료서비스 이용자들에게 그 이용기간을 연장하거나 환불하는
              등의 방법으로 손실을 보상하여야 합니다.
            </Typography>

            <Typography className={classes.textsub}>제18조 (정보의 제공 및 광고의 게재)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 “회원"에게 서비스 이용에 필요하거나 서비스 개선 및 서비스 소개, “채용관리"의 채용상태 변경
              알림 등의 목적으로 하는 각종 정보에 대해서 이메일, 휴대폰 문자메시지, SNS, 푸시알림 및 “서비스" 내 알림
              등을 이용한 방법으로 제공할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 제공하는 서비스와 관련된 정보 또는 광고를 “서비스" 화면 게재 및 제1과 같은 방법을 통해
              “회원"에게 제공할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회원"은 제1항 및 제2항에 제공되는 정보의 수신에 대하여 수신거절을 “회사"에게 할 수 있으며, “회사"는
              수신거절을 한 “회사"에게는 제1항 및 제2항의 정보 제공을 하지 아니합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회원"은 별도의 수신 거절 의사를 표시하지 않는 한 “서비스" 이용 중 정보 제공 및 알림, 광고 게재에 대해
              동의하는 것으로 간주합니다.
            </Typography>
            <Typography className={classes.text}>
              5. 만약 “회원"이 제1항의 정보에 대하여 수신거절을 하여 그 정보를 수신하지 못함으로 발생하는 손실 및 손해에
              대하여 책임지지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회사"는 “회원"이 제2항의 광고에 “회원"이 참여하거나 해당 광고의 광고주와 “회원"이 교신 또는 거래를
              함으로써 발생하는 모든 손실과 손해에 대하여 책임지지 않습니다.
            </Typography>

            <Typography className={classes.textsub}>제19조 (자료내용의 책임과 “회사"의 정보 수정 권한)</Typography>
            <Typography className={classes.text}>
              1. 자료란 “회원"이 등록한 개인정보 및 “사업장" 정보, “구인조건" 정보, “채용관리"의 지원자 현황 및 지원자
              정보, 채용진행 현황 정보, 그 외 “회원"이 작성한 글을 말합니다 (이하 “자료").
            </Typography>
            <Typography className={classes.text}>
              2. “회사"는 “회원"이 등록하는 모든 글에 대하여 회원의 IP와 연락처 정보, 생년월일 정보는 비공개를 원칙으로
              합니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회원"은 자료 내용을 사실에 근거하여 성실하게 작성해야 합니다. 만일 자료의 내용이 허위이거나 부정확할
              경우 이로 인해 발생하는 책임은 “회원"에게 있습니다.
            </Typography>
            <Typography className={classes.text}>
              4. 모든 자료 내용의 관리와 작성은 “회원” 본인이 하는 것이 원칙입니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 “회원"이 등록한 자료에 대하여 오자, 탈자 또는 사회적 통념이나 미풍양속, 관계법령에 어긋나는
              문구와 내용이 있을 경우 언제든지 수정할 수 있습니다. 또한, “구직조건"과 “이력서"의 경력정보의 경우, 제10조
              제2항, 제3항, 제4항에 따라 자동으로 특정 정보를 수정 또는 변경할 수 있으며, “구인공고"의 내용 또는
              관계법령, “회사"의 운영 정책 등에 의해 수정 또는 삭제 할 수 있습니다. “회원"은 주기적으로 자신의 자료를
              확인하여 실제 본인의 구직 상황에 맞게 항상 정확하게 관리가 되도록 해야 합니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회원"이 등록한 자료로 인해 타인 또는 타 법인으로부터 허위사실 및 명예훼손, 업무방해, 저작권 또는
              지적재산권 침해 등의 사유로 삭제 요청이 접수되거나, “서비스"의 건전한 운영을 저해한다고 “회사"가 판단하는
              경우 “회사"는 “회원"에게 사전 통지 없이 해당 자료를 삭제할 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              7. 만약 특정 자료에 대하여 “회원"과 제3자 간의 분쟁이 있거나, 제3자의 신고 또는 민원이 있을 경우, “회사"는
              일시적으로 해당 자료의 효력 및 게시를 중단시키고 이를 “회원"과 해당 제3자에게 사후 통지할 수 있습니다.
              또한, “회사"는 해당 자료에 대하여 “회원"과 해당 제3자를 포함한 이용자들의 접근을 임시적으로 차단할 수
              있습니다.
            </Typography>

            <Typography className={classes.textsub}>
              제20조 (저작권 및 권리의 귀속, 자료 내용의 활용 및 취급)
            </Typography>
            <Typography className={classes.text}>
              1. “회사"가 작성한 저작물에 대한 저작권과 지적재산권, 사용권은 “회사"에 귀속됩니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회원"이 “서비스” 이용 중에 작성한 “자료”의 저작권은 “회원"에게 귀속됩니다. 단, “회원"은 자신이 등록한
              “자료"에 대해 “회사”가 “서비스"를 운영하는 동안 무상의 비독점적 사용권을 “회사"에게 부여합니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 제2항의 “회원"이 등록한 “자료"를 다음과 같이 활용할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) 해당 “회원"의 자료를 이용, 편집, 재가공하는 형식의 변경 및 기타 변형, 회사가 사전에 공지한 제휴 사이트
              등에 “회원"의 구인공고를 게재하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차 저작물 작성, 제휴사 제공 등)
            </Typography>
            <Typography className={classes.text2}>
              2) 해당 “회원"의 자료를 재가공, 분석함으로써 산출하는 통계 자료 및 보고서 제작
            </Typography>
            <Typography className={classes.text2}>
              3) “회사"가 “서비스”를 제공, 홍보, 개선하기 위해, 또는 “회사"를 홍보하기 위한 목적으로 “자료"를 “서비스"
              내에 전송하거나 기타 미디어, 매체, 언론사 등을 통해 발표, 방송, 배포, 게시하는 것
            </Typography>
            <Typography className={classes.text2}>
              4) 기타 “회사"가 “서비스"운영 및 경영 상의 사유로 필요하다고 판단하는 경우
            </Typography>
            <Typography className={classes.text}>
              4. 단, 제3항의 활용에 있어서 “회원" 개인을 식별할 수 있는 개인정보(“사업장" 정보가 아닌 가입자 또는
              채용담당자 본인의 성명, 생년월일(단, 연령 정보는 제외)을 조합한 정보를 포함한 개인 식별정보)는 제외합니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회원"은 제3항의 정보 활용에 있어 본인의 법률상 이익이 침해되었다고 판단할 경우, “회사"에게 해당 정보
              활용의 중단 또는 해당 재가공 정보의 삭제 또는 수정을 요청할 수 있습니다. 이 경우, “회사"는 신속하게 필요한
              조치를 취하고 이를 해당 요청자에게 통지합니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회원"은 제1항의 “회사" 저작물 및 다른 회원의 “자료"에 대하여 “회사"가 정한 이용조건에 따라 “서비스"
              내에서 이용할 수 있는 권한만을 부여받습니다. “회사"는 “서비스"를 이용함으로써 얻은 정보를 “회사"의 승낙
              없이 “서비스" 외의 영역에서 활용하거나, 영리목적으로 이용, 또는 타인에게 제공, 양도해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text}>
              7. 본 조는 “회사"가 서비스를 운영하는 동안 유효하며, “회원"의 탈퇴 후에도 지속적으로 적용됩니다.
            </Typography>

            <Typography className={classes.textsub}>제21조 (회사의 의무)</Typography>
            <Typography className={classes.text}>1. “회사"는 관련법령에 반하는 행위를 하지 않습니다.</Typography>
            <Typography className={classes.text}>
              2. “회사"는 계속적이고 안정적인 서비스를 제공하기 위해 노력합니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 “회원"에게 최선의 “매칭" 및 “채용관리" 서비스를 제공하고 그 서비스 품질을 지속 발전시킴으로써,
              “회원"이 “서비스"를 통해 본인에게 적합한 일자리를 구직할 수 있도록 최선을 다합니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 “회원"이 등록한 “구인공고"에 대하여 구직 지원이 발생하거나, “채용관리" 상의 채용진행 상태의
              변경사항이 있을 경우, 이를 신속하게 “회원"에게 “서비스" 내 알림 및 기타 수단을 통하여 통지함으로써,
              “회원"의 수월한 구인활동을 지원합니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 건전한 구인, 구직 활동을 반복적으로 저해한 사실이 분명히 확인되는 다른 이용자에 의해 “회원"이
              “서비스" 이용 중 피해를 받지 않도록 최선을 다합니다.
            </Typography>
            <Typography className={classes.text}>
              6. “회사"는 본 약관 및 [개인정보처리방침], [위치정보 서비스 이용약관], [개인정보 수집 및 이용동의],
              [개인정보 제3자 제공 동의]의 내용을 준수합니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회사"는 “서비스"와 관련한 “회원"의 불만사항이 접수되는 경우, 그 불만이 정당하다고 인정할 경우 적절한
              절차를 따라 신속히 처리하여야 합니다. 만약 즉시 처리가 곤란한 경우 그 사유와 처리 일정을 “서비스" 또는
              기타 통지 수단을 통해 “회원”에게 통지하여야 합니다.
            </Typography>
            <Typography className={classes.text}>
              8. “회사"는 회원의 “자료”를 본 약관 및 [개인정보처리방침], [개인정보 제3자 제공 동의]에 명시되지 않은 이유
              또는 “서비스" 이외의 목적으로 제3자에게 제공하거나 열람시킬 경우, 반드시 “회원"의 동의를 얻어야 합니다.
            </Typography>
            <Typography className={classes.text}>
              9. “회사"는 “회원"의 개인정보 보호를 위해 최선을 다하여야 하며, “회원"의 자료가 손실, 망실, 유실되지
              않도록 최선을 다하여야 합니다. 단, 천재지변 및 시스템 장애, “회사"가 예측하지 못한 사유에 의한 경우, 이로
              인해 발생하는 손해에 대해서는 “회사"는 책임을 지지 않습니다. 다만, 그럼에도 불구하고, “회사"는 “자료"의
              복구 및 정상적인 서비스 재개를 위해 최선을 다할 의무를 집니다.
            </Typography>

            <Typography className={classes.textsub}>제22조 (이용자 및 “회원"의 의무)</Typography>
            <Typography className={classes.text}>
              1. “회원"과 이용계약을 체결하지 않은 이용자를 포함한 모든 “이용자"는 다음과 같은 사항을 해서는 아니되는
              의무가 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              1) “서비스"를 건전한 구인 및 인력관리, 기타 고용활동 이외의 목적으로 이용하는 행위
            </Typography>
            <Typography className={classes.text2}>
              2) “회사" 및 “서비스"의 운영자, “회사" 내 다른 구직자 회원 및 구인자 회원으로 오인할 우려가 있는 행위
            </Typography>
            <Typography className={classes.text2}>3) 허위 “자료"를 등록하는 행위</Typography>
            <Typography className={classes.text2}>
              4) 다른 구직자 회원 및 구인자 회원에게 직거래를 유도하거나 시도하거나, 상대방의 직거래 요청에 응하는 행위
            </Typography>
            <Typography className={classes.text2}>
              5) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위
            </Typography>
            <Typography className={classes.text2}>
              6) “서비스" 프로그램 및 클라이언트를 조작하거나, 악성코드, 바이러스, 스팸을 발송, 또는 서버 해킹을 하거나,
              또는 이러한 행위를 시도하는 행위
            </Typography>
            <Typography className={classes.text2}>
              7) “회사"의 사전 서면 동의 없는 “서비스"를 이용한 영리 행위
            </Typography>
            <Typography className={classes.text2}>
              8) 제20조 제6항을 위반하여, 임의로 “서비스" 이용 이외의 목적으로 “서비스"의 내용 및 게시물을 이용하거나,
              배포, 복사, 복제, 출판하는 행위
            </Typography>
            <Typography className={classes.text2}>9) 타인 또는 “회사"의 명예 및 권리를 침해하는 행위</Typography>
            <Typography className={classes.text2}>
              10) 다른 “회원"의 계정 또는 “사업장" 정보를 도용하거나 부정 사용, 또는 양도하거나 양도받는 행위
            </Typography>
            <Typography className={classes.text2}>
              11) 범죄 행위를 목적으로 하거나 기타 범죄행위와 관련한 행위
            </Typography>
            <Typography className={classes.text2}>
              12) 기타 “서비스"의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위
            </Typography>
            <Typography className={classes.text2}>
              13) 기타 선량한 풍속, 기타 사회 질서를 해하거나 관계법령에 위반하거나, “회사"의 업무를 방해하는 행위
            </Typography>
            <Typography className={classes.text}>2. 제1항에 더하여, “회원"은 다음과 같은 의무가 있습니다.</Typography>
            <Typography className={classes.text2}>
              1) 본 약관의 규정 및 기타 “회사"가 통지하는 사항을 준수하여야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              2) “서비스" 이용을 위한 “회원"의 아이디 및 비밀번호, 기타 계정 정보를 관리하는 의무. 본인 ID 등 계정
              정보의 제3자에 의한 부정사용 등 회원의 고의 또는 과실로 인해 발생하는 모든 불이익에 대한 책임은 “회원"이
              부담합니다. 단, 이것이 회사의 고의 또는 중과실로 인하여 야기되는 경우에는 회사가 책임을 집니다.
            </Typography>
            <Typography className={classes.text2}>
              3) “회원"은 유료서비스 이용 시, 신청과 동시에 “회사"와 채권, 채무 관계가 발생하며, “회원"은 이에 대한
              요금을 지정된 기일 내에 납부하여야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              4) “회원"은 유료서비스 이용 시 사용한 결제수단의 비밀번호 및 카드번호 등 정보유실 방지에 대하여 스스로
              관리해야 합니다. 단, “서비스" 자체의 기술적 결함에 따른 정보유실의 발생에 대한 책임은 “회원"의 의무에
              해당하지 않습니다.
            </Typography>
            <Typography className={classes.text2}>
              5) 모든 “자료"는 실제 사실에 근거하여 등록하여야 합니다. 특히, “이력서"의 경력정보 및 “프로필" 정보는 허위
              또는 과장된 정보로 작성해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text2}>
              6) “회원" 본인이 실제 구인의사가 있는 경우에 한하여, 실제 채용 시 부여할 근무조건과 다르지 않는 조건으로
              “구인공고"를 등록해야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              7) “매칭" 시, 구직자 회원에게 채용 제안을 하거나, “채용관리"에서 채용 단계를 진행하고자 할 때, 실제 본인이
              구인 의사가 있는 구직자 회원에 대해서만 지원하여야 합니다. 만약 실제 구인 의사가 없는데도 구직지원을
              반복하여 “회사"의 서비스 운영 및 해당 구인자 회원의 구인 활동에 지장을 초래할 경우, “회사"는 서비스
              이용계약 해지 및 제한, 또는 손해배상 등의 조치를 취할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              8) 본인의 “구인공고"에 채용 진행 중이거나 구직 지원을 한 구직자 회원에 대하여, “채용관리"를 통해 신속,
              성실히 응대하여야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              9) 본인이 채용 진행 중이거나 본인에게 구직 지원을 한 구직자 회원에 대하여, 해당 구직자 회원과 “채용관리"를
              통해 협의한 면접 및 출근 및 채용 약속 및 그 시간을 성실히 준수하여야 합니다. 만약 약속 불이행 또는
              지각으로 인해 상대방 구직자 회원으로부터의 신고가 누적될 경우, “회사"는 “회원"의 서비스 이용을 해지하거나
              제한할 수 있습니다.
            </Typography>
            <Typography className={classes.text2}>
              10) “회원"은 실제 사실에 근거하지 않고 부당하게 다른 이용자를 비방하거나, “회사"가 “회원"에게 구직자
              회원의 평가 및 검증을 요청할 시 허위 또는 부당한 평가를 해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text2}>
              11) “회원"은 [근로기준법] 및 [직업안정법]을 준수해야 합니다.
            </Typography>

            <Typography className={classes.text2}>
              12) “회원"은 구인공고에 최저시급 등 당해년도 최저임금 이상의 급여를 등록하여야 하며, “서비스"를 통해 실제
              “구직자 회원”을 채용 시, 구인공고에 게재한 금액과 동일한, 당해년도 최저임금 이상의 급여를 지급해야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              13) [직업안정법] 및 [청소년보호법]에 따라, “회원"은 밤 10시 이후부터 오전 6시까지의 심야 근무 시간에 19세
              미만의 청소년 “구직자 회원"을 채용해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text2}>
              14) [직업안정법] 및 [청소년보호법]에 따라, “회원"의 업종이 다음 각 호 중 어느 하나에 해당할 경우, 19세
              미만의 청소년 “구직자 회원"을 채용해서는 아니됩니다.{" "}
            </Typography>
            <Typography className={classes.text22}>i) 밤 10시 이후부터 오전 6시까지의 심야 근무</Typography>
            <Typography className={classes.text22}>
              ii) 단란주점 및 유흥주점, 비디오방, PC방(종합 게임장), 노래방, 만화방, 전화방, 무도장, 장외발매소(경마장)
              등
            </Typography>
            <Typography className={classes.text22}>
              iii) 숙박업, 이용업, 안마실(일반마사지 업소도 포함)이 설치된 목욕장, 티켓다방, 유독물 제조▪판매업 등
            </Typography>
            <Typography className={classes.text22}>iv) 주류판매 목적의 소주방, 호프집, 카페 등</Typography>
            <Typography className={classes.text22}>
              v) 고압▪잠수업, 교도소 또는 정신병원, 소각 또는 도살 업무, 갱내(광산에서 광물을 채취하기 위해 파놓은
              구덩이의 안)
            </Typography>
            <Typography className={classes.text22}>vi) 유류(기름)를 취급하는 업무 (주유소 제외)</Typography>
            <Typography className={classes.text22}>
              vii) 운전(조종) 면허 취득을 제한하고 있는 업종의 운전(조종) 업무
            </Typography>
            <Typography className={classes.text22}>ⅷ) 2-브로모프로판 취급 또는 노출 업무</Typography>
            <Typography className={classes.text2}>
              15) [남녀고용평등과 일▪가정 양립 지원에 관한 법률], [고용상 연령차별금지 및 고령자 고용촉진에 관한 법률]에
              따라, “회원"은 채용 시, 특정 연령, 또는 특정 성별을 배제하거나, 우대해서는 아니되며, 구인공고 상에 특정
              연령, 또는 특정 성별만을 채용하거나 우대한다는 내용을 게재해서는 아니됩니다. 구인공고 게재 시 선호
              조건으로 연령, 성별을 입력하실 경우, 해당 조건은 “서비스가" 추천 서비스를 제공 시에 참고 자료로만
              활용되며, 공고 게재 여부 및 실제 채용 조건과는 무의미합니다. 본 항의 내용에도 불구하고, “회원" 또는 채용
              직무가 다음 각 호 중 어느 하나의 경우에 해당할 경우는 본 항의 내용을 적용하지 않습니다.
            </Typography>

            <Typography className={classes.text22}>
              i) “회원"의 업종 또는 채용 직무가 미성년자 고용 금지 업종에 해당함에 따른 미성년자 제한의 경우
            </Typography>
            <Typography className={classes.text22}>
              ii) 관계 법령이나 근로계약, 취업규칙, 단체협약 등에서 정년을 설정하는 경우
            </Typography>
            <Typography className={classes.text22}>
              iii) 직무의 성격에 비추어 특정 연령 또는 성별 기준이 불가피하게 요구되는 경우
            </Typography>
            <Typography className={classes.text22}>
              iv) 관계법령에 따라 특정 집단의 고용유지 및 촉진을 위한 지원조치를 하는 경우
            </Typography>

            <Typography className={classes.text2}>
              16) “회원"은 제6조 제2항 및 제7조에서 규정한 금지사항을 위반하지 않아야 합니다.
            </Typography>
            <Typography className={classes.text2}>
              17) 허위 구인공고로 인해 발생된 문제에 따른 모든 법적인 책임은 작성자인 “회원"에게 있으며, 해당 구인공고
              게재 및 채용 과정에서 “회원"이 지불한 대금은 일체 반환되지 않습니다. 허위 구인광고 또는 허위 구인 조건을
              제시한 자는 [직업안정법] 제47조에 의해 5년 이하의 징역 또는 2천만원 이하의 벌금형을 받을 수 있습니다.
            </Typography>
            <Typography className={classes.text}>
              3. 만약 “회원" 또는 이용자가 본조의 규정이나 정신을 위반하거나, 또는 그러할 우려가 있다고 판단되는 경우,
              “회사"는 서비스 전체나 일부의 제공을 중단할 수 있습니다.
            </Typography>

            <Typography className={classes.textsub}>제24조 (손해배상 및 면책)</Typography>
            <Typography className={classes.text}>
              1. “회사"가 이 약관의 규정을 위반한 행위로 “회원"에게 손해를 입히거나 기타 “회사"가 제공하는 “서비스"와
              관련하여 “회사"의 책임있는 사유로 인해 “회원"에게 손해가 발생한 경우 “회사"는 그 손해를 배상해야 합니다.
            </Typography>
            <Typography className={classes.text}>
              2. 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우, 또는 “회원" 및 타 회원의
              귀책사유로 인한 서비스 이용의 장애에 대해서는 “회사"의 서비스 제공에 관한 책임이 면제됩니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회사"는 “회원"이 “서비스"를 이용하여 기대하는 수익을 얻지 못하거나 상실한 경우에 대하여 책임을 지지
              않습니다.
            </Typography>
            <Typography className={classes.text}>
              4. “회사"는 “회원"이 “서비스" 내에 등록한 정보, “자료" 등의 사실 유무 신뢰도, 정확성 등에 관하여는 책임을
              지지 않습니다. 단, 그 오류가 “회원"의 귀책이 아닌 “회사"의 기술적 중과실 또는 고의에 의한 경우에는 책임을
              집니다.
            </Typography>
            <Typography className={classes.text}>
              5. “회사"는 다른 회원 및 구인자 회원, 제휴회사가 제공하거나 등록한 구인 정보 등의 정보 및 자료의 사실 유무
              신뢰도, 정확성 등에 대하여 책임을 지지 않습니다.
            </Typography>
            <Typography className={classes.text}>
              6. 다른 회원(구인자 회원, 구직자 회원, 기타 유형의 회원 모두 포함)의 귀책 사유로 “회원"에게 손해가 발생한
              경우 “회사"는 책임이 없습니다.
            </Typography>
            <Typography className={classes.text}>
              7. “회원"이 고의 또는 본 약관을 위반한 행위, 또는 “회원"의 책임 있는 사유로 인해 “회사" 또는 제3자에게
              손해를 입힌 경우에 “회원"은 그 손해를 배상하여야 할 책임이 있습니다.
            </Typography>
            <Typography className={classes.text}>
              8. “회원"은 본인에게 매칭되었거나 “서비스"를 통해 본인이 채용한 “구직자 회원"을 타인에게 파견하거나,
              “구인공고"에 해당하는 “사업장”이 아닌 본인이 운영하는 다른 사업장으로 발령, 고용 이관을 해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text}>
              9. “회원"은 “서비스"를 통해 매칭, 채용한 “구직자 회원"을 “회원" 본인이 다른 경로로 채용한 다른 직원들과
              동등하거나 더 상회하는 조건으로 대우해야 하며, “회원" 본인이 등록한 “구인공고" 및 “채용관리"에서 협의한
              근로조건과 다른 조건(단, 임금 및 복지 등 보상이 이 조건보다 상회하는 경우는 예외)으로 실제 채용하거나
              근무할 것을 요구, 유도해서는 아니됩니다.
            </Typography>
            <Typography className={classes.text}>
              10. 본조 각 항에도 불구하고, “회사"와 “회원"은 그 책임 사유에 대하여 상대방의 시정 요구에도 불구하고 이를
              시정 요구일로부터 30일 또는 상대방이 고지한 기일 중 선도래하는 날까지 시정하지 않았을 경우 손해배상 의무가
              발생합니다.
            </Typography>

            <Typography className={classes.textsub}>제25조 (양도 금지)</Typography>
            <Typography className={classes.text}>
              1. “회원"의 아이디 및 비밀번호 등 계정 정보는 제3자에게 양도, 대여, 증여할 수 없습니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회원"의 “서비스" 이용과 관련된 권리 및 의무는 제3자에게 양도, 대여, 증여 등으로 사용할 수 없습니다.
            </Typography>
            <Typography className={classes.text}>
              3. “회원"은 “회사"에 사전 통지 및 “회사"의 확인, 동의 없이 본인의 “사업장" 정보 및 “구인공고" 및 본인에게
              지원하였거나 매칭된 구직자 회원의 정보를 타인에게 양도, 대여, 증여할 수 없습니다.
            </Typography>

            <Typography className={classes.textsub}>제26조 (“회원" 개인정보 보호)</Typography>
            <Typography className={classes.text}>
              “회사"는 “회원"의 개인정보보호를 위하여 노력하여야 합니다. “회원"의 개인정보보호에 관해서는
              [정보통신망이용촉진 및 정보보호 등에 관한 법률], [개인정보보호법]에 따르고, “서비스" 내에
              [개인정보처리방침]을 고지합니다.
            </Typography>

            <Typography className={classes.textsub}>제27조 (분쟁의 해결)</Typography>
            <Typography className={classes.text}>
              1. “회사"는 “회원"으로부터 제기되는 불만사항 및 의견을 가능한 신속하게 처리하기 위해 노력합니다.
            </Typography>
            <Typography className={classes.text}>
              2. “회사"와 “회원"은 “서비스"와 관련하여 발생하는 분쟁을 상호 원만하게 해결하기 위하여 필요한 모든 노력을
              하여야 합니다.
            </Typography>
            <Typography className={classes.text}>
              3. 전항의 노력에도 불구하고, 협의에 의해 해결되지 않아 소송이 제기 되야 하는 경우 동 분쟁에 관한 소송은
              “회사"의 소재지 관할법원으로 합니다.
            </Typography>

            <Typography className={classes.texttitle} style={{ marginTop: "49px" }}>
              부칙
            </Typography>

            <Typography className={classes.textsub}>제1조 (약관의 효력 및 변경)</Typography>
            <Typography className={classes.text}>
              1. 이 약관은 2021년 4월 8일부터 시행합니다.
              <br />
              2. 이 약관은 2022년 3월 2일 개정되었으며, 2022년 3월 9일부터 시행합니다.
              <br />
              3. 이 약관은 2023년 10월 17일 개정되었으며, 2023년 10월 18일부터 시행합니다.
            </Typography>

            <Typography className={classes.text2} style={{ marginTop: "32px" }}>
              주식회사 웍스비
            </Typography>
            <Typography className={classes.text2}>대표 김 현 호</Typography>
            <Typography className={classes.text2}>서울시 서초구 매헌로16길 32, 301호</Typography>
            <Typography className={classes.text2}>사업자등록번호 122-86-47366</Typography>
            <Typography className={classes.text2}>직업정보제공사업신고번호: 서울청 제2023-9호</Typography>
            <Typography className={classes.text2}>통신판매업신고번호: 제 2023-서울서초-1611호</Typography>
            <Typography className={classes.text2} style={{ marginBottom: "32px" }}>
              고객센터 help@worksbe.com, 1644-2163
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(termsServiceBusinessStyles)(TermsServiceBusiness);
