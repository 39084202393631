import React, {useState} from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import {analyticsEvent, searchEncoding} from "../../lib/common"
import {useLocation} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja0Styles = makeStyles({
    background: {
        backgroundColor: '#FFF',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
    },
    blueButton: {
        backgroundColor: '#2E2F6CEE',
        width: '160px', 
        height: '70px',
        borderRadius: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '32px',
    },
    blueButtonText: {
        fontSize: '24px',
        fontFamily: 'Noto Sans Korean',
        lineHeight: '35px',
        marginBottom: '1px',
        color: '#FFF',
        fontWeight: 700,
    }
})

const TheBulja0 = props => {
    const classes = TheBulja0Styles()
    const isss = useMediaQuery('(min-width:1281px)')
    const location = useLocation()
    const [isShow, setIsShow] = useState(false)
    const [topIndex, setTopIndex] = useState(120)

    const clickHandler = () => {
        analyticsEvent('HOMEPAGE', 'GO_DOWNLOAD', String.format('FROM:{0}|{1}', searchEncoding(location.pathname), searchEncoding(location.search)))
        window.open(String.format('{0}{1}', 'https://thebulja.page.link/app', location.search?location.search:''), '_blank')
    }
    let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    const onScrollHandler = () => {
        if (isss) {
            return
        }
        if (window.pageYOffset > viewportHeight * 0.925) {
            setIsShow(true)
            // console.log(window.pageYOffset, screenHeight, viewportHeight, viewportScale)
            setTopIndex(Math.max(viewportHeight * 0.925 - window.pageYOffset, -120))
        } else {
            setIsShow(false)
        }
    }

    window.onscroll = onScrollHandler


    return (
        <Box style={(isss || !isShow) ? {display: 'none'} : {position: 'fixed', height: '120px', width: '100%', backgroundColor: '#FFF', zIndex: 1000, top: -120 -topIndex}}>
            <Grid container className={classes.background}>
                <img src={require('../../images/thebulja/thebulja_icon_S2.svg').default} style={{height: '64px', marginLeft: '32px'}} alt='' />
                <Box>
                    <a href={String.format('{0}{1}', 'https://thebulja.page.link/app', location.search?location.search:'')} target='_blank' onClick={clickHandler}>
                        <Box className={classes.blueButton}>
                            <Typography className={classes.blueButtonText}>앱 다운로드</Typography>
                        </Box>
                    </a>
                </Box>
            </Grid>
        </Box>
    )
}

export default TheBulja0
