import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter} from "react-router-dom"
import ReactGA from "react-ga4"
import {createBrowserHistory} from "history"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import SentryRRWeb from "@sentry/rrweb"
import HttpsRedirect from 'react-https-redirect'
import {getServiceUrl, onLive} from "./lib/common"
import { createRoot } from 'react-dom/client'
import axios from "axios"


const history = createBrowserHistory()

if (onLive()) {
    ReactGA.initialize('G-FR11Z8LFYM')
    history.listen((location) => {
        ReactGA.set({ page: location.pathname }) // Update the user's current page
        ReactGA.send({ hitType: 'pageview', page: location.pathname })
    });
}

Sentry.init({
    dsn: "https://2bf6fe90aaa847179c3dbf814af2185d@o311626.ingest.sentry.io/5958080",
    integrations: [
        new Integrations.BrowserTracing(),
        new SentryRRWeb(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: onLive() ? "production" : "development",
});

const hasReplays = true
Sentry.setTag("rrweb.active", hasReplays ? "yes" : "no");

axios.defaults.baseURL = getServiceUrl()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <BrowserRouter history={history}>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </BrowserRouter>
)

reportWebVitals()
