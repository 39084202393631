import React from 'react'
import {Box, Grid, makeStyles, Typography} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery'



const TheBulja5Styles = makeStyles({
    background: {
        backgroundColor: '#FFF',
        justifyContent: 'center',
        minHeight: '1280px',
    },
    center_box: {
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        minWidth: '1240px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row',
        margin: '14.1406vh auto',
    },
    center_box_m: {
        width: 'calc(100vw - 17.7083vw - 17.7083vw)',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        flexFlow: 'column',
    },
    page_phone: {
        width: '17.7083vw',
        minWidth: '340px',
        borderRadius: '2vw',
        boxShadow: '0.2vw 0.3vh 60px #20202022',
        margin: '4.7656vh 0 4.7656vh 0',
    },
    page_phone2: {
        width: '15.625vw',
        minWidth: '300px',
        borderRadius: '2vw',
        boxShadow: '0.2vw 0.3vh 60px #20202022',
        marginRight: '-2.5vw',
    },
    
    text_box: {
        //position: 'absolute',
        left: '0%', 
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',    
        height: '34.2188vh',
    },
    text_box_m: {
        //position: 'absolute', 
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        marginTop: '160px',
    },
    text_light: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 300,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',

    },
    text_bold: {
        fontFamily: 'Noto Sans Korean',
        fontSize: '2.3vw',
        lineHeight: '126%',
        fontWeight: 700,
        color: '#333375',
        letterSpacing: '-2px',
        whiteSpace: 'nowrap',
        position: 'relative',  
    },
})

const TheBulja5 = props => {
    const classes = TheBulja5Styles()
    const isss = useMediaQuery('(min-width:1281px)')


    return (
        <Box style={{height: '100%', width: '100%', backgroundColor: '#FFF', position: 'relative'}}>
            <Grid container direction='column' className={classes.background}>
                {isss ?
                <Grid container className={classes.center_box}>
                    <Grid item xs={6}>
                        <Grid className={classes.text_box}>
                            <img src={require('../../images/thebulja/page5_red_box.svg').default} style={{width: '6.77vw'}} alt='' />
                            <Box style={{minHeight: '2.89vh'}}/>
                            <Typography className={classes.text_light}>내가 원하는 맞춤조건</Typography>
                            <Typography className={classes.text_light}>(장소, 시간, 소득 등)만 입력하면,</Typography>
                            <Typography className={classes.text_bold}>최적의 기회를 한 번에</Typography>
                            <Typography className={classes.text_bold}>찾아 드립니다.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <img src={require('../../images/thebulja/page5_img.svg').default} className={classes.page_phone2} alt=''/>
                        <img src={require('../../images/thebulja/page5_img2.svg').default} className={classes.page_phone} alt=''/>
                    </Grid>
                </Grid>
                :
                <Grid container className={classes.center_box_m}>
                <Grid item>
                    <Grid className={classes.text_box_m}>
                        <img src={require('../../images/thebulja/page5_red_box.svg').default} style={{width: '156px'}} alt='' />
                        <Box style={{minHeight: '37px'}}/>
                        <Typography className={classes.text_light} style={{fontSize: '46px'}}>내가 원하는 맞춤조건</Typography>
                        <Typography className={classes.text_light} style={{fontSize: '46px'}}>(장소, 시간, 소득 등)만 입력하면,</Typography>
                        <Typography className={classes.text_bold}  style={{fontSize: '46px'}}>최적의 기회를 한 번에</Typography>
                        <Typography className={classes.text_bold}  style={{fontSize: '46px'}}>찾아 드립니다.</Typography>
                    </Grid>
                </Grid>
                    <Grid item style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '65px 0 35px 0'}}>
                        <img src={require('../../images/thebulja/page5_img.svg').default} className={classes.page_phone2} style={{borderRadius: '27.4px'}} alt=''/>
                        <img src={require('../../images/thebulja/page5_img2.svg').default} className={classes.page_phone} style={{margin: 0, borderRadius: '30.22px'}} alt=''/>
                    </Grid>
                </Grid>
                }
            </Grid>
            
        </Box>
    )
}

export default TheBulja5
